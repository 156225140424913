import { fetchWithToken } from 'utils/fetch';
import { uriEncodeBody, urlEncodeBody, checkSuccess } from 'utils/requests';
import { GetTransactionsParams, Income, Transaction } from 'views/TransactionsPage/transactions.d';
import { info } from 'sitedata';

const { commonApiUrl } = info;

const mapTransaction = (item): Transaction => ({
  id: item.stripe_order_id,
  name: item.name,
  payer: item.payer,
  amount: item.amount,
  commission: item.commission,
  receiptNumber: item.receipt_number,
  date: item.created_on * 1000,
  refunded: item.refunded,
  refundedAmount: item.refund_amount,
  type: item.type,
  phone: item.phone,
  email: item.email,
  addons: item.addons,
  comment: item.comment,
  charged: item.charged
});

export const getTransactions = async (
  params: GetTransactionsParams
): Promise<{ transactions: Transaction[]; totalPages: number }> => {
  const query = uriEncodeBody({
    listing_id: params.listingId,
    type: params.type || 'all',
    offset: params.offset,
    limit: params.limit,
    sort_order: params.sortOrder,
    sort_by: params.sortBy,
    search: params.search
  });
  const response = await fetchWithToken(`${commonApiUrl}/billing/transactions`, {
    query
  });
  const data = await response.json();

  checkSuccess(data);

  const transactions = data.transactions.map(mapTransaction);

  return { transactions, totalPages: data.total_pages };
};

export const getIncomes = async (params: {
  startDate: number;
  endDate: number;
}): Promise<{
  incomes: Array<Income>;
  totalPackages: number;
  totalAddons: number;
}> => {
  const query = uriEncodeBody({
    start_date: params.startDate,
    end_date: params.endDate
  });
  const response = await fetchWithToken(`${commonApiUrl}/billing/incomes`, {
    query
  });
  const data = await response.json();

  checkSuccess(data);

  const incomes = data.incomes.map(({ _id, ...item }) => ({
    date: _id,
    ...item
  }));

  return {
    incomes,
    totalPackages: data.totalPackages,
    totalAddons: data.totalAddons
  };
};

export const getExpenses = async (params: {
  startDate: number;
  endDate: number;
}): Promise<{
  expenses: Array<Income>;
  totalSiteMonthlyFee: number;
  totalUserFee: number;
  totalClosedDealFee: number;
}> => {
  const query = uriEncodeBody({
    start_date: params.startDate,
    end_date: params.endDate
  });
  const response = await fetchWithToken(`${commonApiUrl}/billing/expenses`, {
    query
  });
  const data = await response.json();

  checkSuccess(data);

  const expenses = data.expenses.map(({ _id, ...item }) => ({
    date: _id,
    ...item
  }));

  return {
    expenses,
    totalSiteMonthlyFee: data.totalSiteMonthlyFee,
    totalUserFee: data.totalUserFee,
    totalClosedDealFee: data.totalClosedDealFee
  };
};

export const refundTransaction = async (id: string, refundAmount: number) => {
  const body = urlEncodeBody({
    refund_amount: refundAmount
  });
  await fetchWithToken(`${commonApiUrl}/billing/refund/${id}`, { method: 'POST', body });
};

export const getTransactionsCSV = async (params: GetTransactionsParams) => {
  const query = uriEncodeBody({
    type: params.type,
    offset: params.offset,
    limit: params.limit,
    sort_order: params.sortOrder,
    sort_by: params.sortBy,
    search: params.search
  });
  const response = await fetchWithToken(`${commonApiUrl}/billing/transactions-csv`, {
    query
  });
  return response.text();
};

export const getTransactionServices = async (): Promise<TransactionService[]> => {
  const response = await fetchWithToken(`${commonApiUrl}/billing/services`);
  return response.json();
};

export const createTransaction = async ({
  listingId,
  ...params
}: {
  listingId: string;
  service: string;
  description: string;
  amount: string;
  name: string;
  email: string;
  phone?: string;
}): Promise<Transaction> => {
  const body = {
    listing_id: listingId,
    ...params
  };
  const response = await fetchWithToken(`/transactions`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' }
  });
  const data = await response.json();
  return mapTransaction(data);
};
