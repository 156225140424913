import React from 'react';

import { formatCurrency } from 'utils/rifmFormatters';
import financialSchema from './data/stepFinancialSchema.json';
import { StepFinancialsData } from './types';

interface FinancialsSummaryProps {
  data: StepFinancialsData;
  onEdit: (value: number) => void;
}

const FinancialsSummary: React.FC<FinancialsSummaryProps> = ({ data, onEdit }) => {
  const renderCheckboxes = group => {
    const formKeys = Object.keys(financialSchema[group]);
    const items = formKeys.filter(key => Boolean(data[key]));

    if (!items.length) return <p className="review-text">N/A</p>;

    return items.map(item => (
      <p className="review-text" key={item}>
        {financialSchema[group][item].label}
      </p>
    ));
  };

  return (
    <div className="review-wrapper">
      <div className="review-header">
        <h3 className="review-title">Financials:</h3>
        <button className="info-block__edit-btn" onClick={() => onEdit(2)}>
          Edit
        </button>
      </div>
      <div className="review-block">
        <div className="review-info-block">
          <p className="review-text">
            <strong>Desired Price: </strong>
            {formatCurrency(Number(data.current_price)) || 'N/A'}
          </p>
        </div>
        {data.sellers?.map((item, idx) => (
          <div className="review-info-block" key={idx}>
            <p className="review-text">
              <strong>Seller: </strong>
              {item.name || 'N/A'}
            </p>
            <p className="review-text">
              <strong>Email: </strong>
              {item.email || 'N/A'}
            </p>
          </div>
        ))}
      </div>
      <div className="review-block">
        <div className="review-info-block">
          <p className="review-text">
            <strong>Listing Termination Date: </strong>
            {data.listing_date_termination || 'N/A'}
          </p>
        </div>
        <div className="review-info-block">
          <p className="review-text">
            <strong>Start Listing not earlier than: </strong>
            {data.start_listing_earlier || 'N/A'}
          </p>
        </div>
      </div>
      <p className="review-text">
        <strong>Upfront Flat Fee: </strong>
        {formatCurrency(data.marketing_cost) || 'N/A'}
      </p>
      <p className="review-text">
        <strong>Total Broker Commissions: </strong>
        {data.sale_commission+'%' || 'N/A'}
      </p>
      <p className="review-text">
        <strong>Coop Commissions: </strong>
        {data.coop_commission+'%' || 'N/A'}
      </p>
      <p className="review-text">
        <strong>Additional Commissions: </strong>
        {formatCurrency(data.additional_sale_commission) || 'N/A'}
      </p>
      <p className="review-text">
        <strong>Early Termination Fee: </strong>
        {formatCurrency(data.reimbursement) || 'N/A'}
      </p>
      <p className="review-text">
        <strong>MLS Fee: </strong>
        {formatCurrency(data.mls_fee) || 'N/A'}
      </p>
      <div className="review-block">
        <p className="review-text">
          <strong>Dual Agency: </strong>
          {data.dual_agency || 'N/A'}
        </p>
      </div>

      <div className="review-block">
        <div className="review-info-block">
          <p className="review-text">
            <strong>PIN: </strong>
            {data.pin || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Last Year Taxes: </strong>
            {formatCurrency(data.last_year_taxes) || 'N/A'}
          </p>
        </div>
        {/*<div className="review-info-block">
          <p className="review-text">
            <strong>Electronic Account Number: </strong>
            {data.electronic_account_number || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Gas Account Number: </strong>
            {data.gas_account_number || 'N/A'}
          </p>
        </div>*/}
      </div>

      <div className="review-block">
        <div className="review-info-block">
          <p className="review-text">
            <strong>Property Leased: </strong>
            {data.property_leased || 'N/A'}
          </p>
        </div>
        {data.property_leased === 'Yes' && (
          <div className="review-info-block">
            <p className="review-text">
              <strong>Lease Expiry Date: </strong>
              {data.lease_expiry_date || 'N/A'}
            </p>
          </div>
        )}
      </div>

      <div className="review-block">
        <div className="review-info-block">
          <h3 className="review-info-block__title">Appliances:</h3>
          {renderCheckboxes('price_includes')}
        </div>
      </div>

      <div className="review-block">
        <div className="review-info-block">
          <h3 className="review-info-block__title">Seller also transfers the following:</h3>
          <p className="review-text">{data.seller_transfers || 'N/A'}</p>
        </div>
        <div className="review-info-block">
          <h3 className="review-info-block__title">The following items are excluded:</h3>
          <p className="review-text">{data.items_excluded || 'N/A'}</p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FinancialsSummary);
