import React from 'react';

import { formatCurrency } from 'utils/rifmFormatters';
import { Image } from 'components';
import noImage from 'assets/images/placeholder_no_image.jpg';
import { IMAGE_SIZES } from 'consts';
import { StepIdentifyPropertyData } from './types';

interface IdentifySummaryProps {
  data: StepIdentifyPropertyData;
}

const IdentifySummary: React.FC<IdentifySummaryProps> = ({ data }) => {
  const propertyPhoto = data.images?.[0];

  return (
    <div className="review-wrapper">
      <div className="review-block">
        <div className="header-property-info">
          <h3 className="review-title">Your property:</h3>
          <span className="property-block">
            {propertyPhoto ? (
              <Image
                cloudFront={propertyPhoto}
                size={IMAGE_SIZES.small}
                error={noImage}
                alt="mini-propetry"
              />
            ) : (
                <img src={noImage} alt="mini-propetry" />
              )}

            <span>
              {data?.address1} <br />
              {data?.address2} {data?.city}, {data?.state} {data?.zip}
            </span>
          </span>
        </div>
        <div className="header-property-info" />
        {data?.current_price && (
          <div className="header-property-info">
            <h3 className="review-title">Desired Price:</h3>
            <p className="property-price">{formatCurrency(data.current_price)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(IdentifySummary);
