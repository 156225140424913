/* eslint-disable max-len */
import React from 'react';

export const OfferAddedIcon = props => (
  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6667 13.3331H15.3334V11.9998C15.3334 11.2665 14.7334 10.6665 14.0001 10.6665C13.2667 10.6665 12.6667 11.2665 12.6667 11.9998V13.3331H11.3334C10.6001 13.3331 10.0001 13.9331 10.0001 14.6665C10.0001 15.3998 10.6001 15.9998 11.3334 15.9998H12.6667V17.3331C12.6667 18.0665 13.2667 18.6665 14.0001 18.6665C14.7334 18.6665 15.3334 18.0665 15.3334 17.3331V15.9998H16.6667C17.4001 15.9998 18.0001 15.3998 18.0001 14.6665C18.0001 13.9331 17.4001 13.3331 16.6667 13.3331ZM24.6667 20.091C24.6667 20.4083 24.3681 20.667 24.0001 20.667H4.00008C3.63208 20.667 3.33341 20.4083 3.33341 20.091V14.3403V3.90833C3.33341 3.59099 3.63208 3.33366 4.00008 3.33366H9.50141L12.9681 7.57099C13.2214 7.88166 13.6001 8.06166 14.0001 8.06166H24.0001C24.3681 8.06166 24.6667 8.31899 24.6667 8.63766V20.091ZM24.0001 5.39499H14.6321L11.1667 1.15633C10.9134 0.845659 10.5334 0.666992 10.1347 0.666992H4.00008C2.16275 0.666992 0.666748 2.12033 0.666748 3.90833V20.091C0.666748 21.879 2.16275 23.3337 4.00008 23.3337H24.0001C25.8374 23.3337 27.3334 21.879 27.3334 20.091V8.63766C27.3334 6.84833 25.8374 5.39499 24.0001 5.39499Z" fill="#DF1721" />
  </svg>

);

export const ScheduleViewRequestIcon = props => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3334 12.6665H15.3334V8.6665C15.3334 7.92917 14.7361 7.33317 14.0001 7.33317C13.2641 7.33317 12.6667 7.92917 12.6667 8.6665V13.9998C12.6667 14.7372 13.2641 15.3332 14.0001 15.3332H19.3334C20.0707 15.3332 20.6667 14.7372 20.6667 13.9998C20.6667 13.2625 20.0707 12.6665 19.3334 12.6665ZM14.0001 24.6665C8.11875 24.6665 3.33341 19.8812 3.33341 13.9998C3.33341 8.1185 8.11875 3.33317 14.0001 3.33317C19.8814 3.33317 24.6667 8.1185 24.6667 13.9998C24.6667 19.8812 19.8814 24.6665 14.0001 24.6665ZM14.0001 0.666504C6.64808 0.666504 0.666748 6.64784 0.666748 13.9998C0.666748 21.3518 6.64808 27.3332 14.0001 27.3332C21.3521 27.3332 27.3334 21.3518 27.3334 13.9998C27.3334 6.64784 21.3521 0.666504 14.0001 0.666504Z" fill="#DF1721" />
  </svg>
);

export const OfferAcceptedIcon = props => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.99982 21C8.80219 21.0006 8.60638 20.962 8.42379 20.8867C8.24121 20.8114 8.07548 20.7008 7.93615 20.5613L0.434318 13.0603C0.155903 12.7776 0 12.3974 0 12.0015C0 11.6056 0.155903 11.2254 0.434318 10.9427C0.573387 10.8025 0.739007 10.6913 0.921592 10.6154C1.10418 10.5394 1.30011 10.5003 1.49799 10.5003C1.69587 10.5003 1.89181 10.5394 2.07439 10.6154C2.25698 10.6913 2.4226 10.8025 2.56167 10.9427L8.84021 17.22L21.3002 0.601053C21.4178 0.443536 21.5655 0.310675 21.7349 0.210163C21.9042 0.109651 22.092 0.043475 22.2871 0.0153641C22.4822 -0.0127468 22.681 -0.00221175 22.872 0.0463842C23.063 0.0949801 23.2425 0.180656 23.4002 0.298515C23.7178 0.538883 23.9276 0.894002 23.9845 1.2871C24.0413 1.68019 23.9407 2.07977 23.7041 2.39957L10.2002 20.3994C10.0718 20.5714 9.90751 20.7137 9.71868 20.8166C9.52985 20.9194 9.32093 20.9804 9.1062 20.9954C9.07125 20.9984 9.03477 21 8.99982 21Z" fill="#DF1721" />
  </svg>
);

export const OfferRejectedIcon = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.1203 12.0008L23.5573 2.56165C23.8382 2.27928 23.9958 1.89804 23.9958 1.50072C23.9958 1.10341 23.8382 0.722165 23.5573 0.439798C23.4183 0.300453 23.253 0.189797 23.0708 0.114322C22.8887 0.038846 22.6933 0 22.496 0C22.2987 0 22.1034 0.038846 21.9213 0.114322C21.7391 0.189797 21.5738 0.300453 21.4348 0.439798L11.9979 9.87888L2.56099 0.439798C2.42204 0.300453 2.2567 0.189797 2.07457 0.114322C1.89244 0.038846 1.69709 0 1.49979 0C1.30248 0 1.10713 0.038846 0.925002 0.114322C0.742869 0.189797 0.577536 0.300453 0.438583 0.439798C0.157611 0.722165 0 1.10341 0 1.50072C0 1.89804 0.157611 2.27928 0.438583 2.56165L9.87551 12.0008L0.438583 21.4399C0.158071 21.7222 0.000740224 22.1032 0.000740224 22.5001C0.000740224 22.897 0.158071 23.278 0.438583 23.5603C0.577536 23.6996 0.742869 23.8102 0.925002 23.8857C1.10713 23.9612 1.30248 24 1.49979 24C1.69709 24 1.89244 23.9612 2.07457 23.8857C2.2567 23.8102 2.42204 23.6996 2.56099 23.5603L11.9979 14.1211L21.4348 23.5603C21.5748 23.6989 21.7409 23.8088 21.9235 23.8836C22.1061 23.9584 22.3018 23.9966 22.4993 23.9962C22.6968 23.9958 22.8922 23.9567 23.0745 23.8811C23.2568 23.8055 23.4224 23.695 23.5618 23.5558C23.7011 23.4166 23.8116 23.2514 23.8867 23.0697C23.9619 22.888 24.0004 22.6934 24 22.4969C23.9996 22.3004 23.9603 22.106 23.8843 21.9246C23.8084 21.7433 23.6972 21.5785 23.5573 21.4399L14.1203 12.0008Z" fill="#DF1721" />
  </svg>
);

export const TaskAddedIcon = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3 24C2.20508 23.9976 1.4434 23.6808 0.88131 23.1187C0.319214 22.5566 0.00237409 21.7949 0 21V4.5C0.00237409 3.70508 0.319214 2.94338 0.88131 2.38129C1.4434 1.81919 2.20508 1.50237 3 1.5H6C6 1.10218 6.15803 0.720666 6.43934 0.439362C6.72064 0.158057 7.10218 0 7.5 0C7.89782 0 8.27936 0.158057 8.56066 0.439362C8.84197 0.720666 9 1.10218 9 1.5H15C15 1.10218 15.158 0.720666 15.4393 0.439362C15.7206 0.158057 16.1022 0 16.5 0C16.8978 0 17.2794 0.158057 17.5607 0.439362C17.842 0.720666 18 1.10218 18 1.5H21C21.7949 1.50237 22.5566 1.81919 23.1187 2.38129C23.6808 2.94338 23.9976 3.70508 24 4.5V10.5C24 10.8978 23.842 11.2793 23.5607 11.5606C23.2794 11.8419 22.8978 12 22.5 12C22.1022 12 21.7206 11.8419 21.4393 11.5606C21.158 11.2793 21 10.8978 21 10.5H3V21H10.5C10.8978 21 11.2794 21.1581 11.5607 21.4394C11.842 21.7207 12 22.1022 12 22.5C12 22.8978 11.842 23.2793 11.5607 23.5606C11.2794 23.8419 10.8978 24 10.5 24H3ZM3 7.5H21V4.5H18C18 4.89782 17.842 5.27933 17.5607 5.56064C17.2794 5.84194 16.8978 6 16.5 6C16.1022 6 15.7206 5.84194 15.4393 5.56064C15.158 5.27933 15 4.89782 15 4.5H9C9 4.89782 8.84197 5.27933 8.56066 5.56064C8.27936 5.84194 7.89782 6 7.5 6C7.10218 6 6.72064 5.84194 6.43934 5.56064C6.15803 5.27933 6 4.89782 6 4.5H3V7.5Z" fill="#DF1721" />
  </svg>
);

export const TaskIcon = props => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3 24C2.20508 23.9976 1.4434 23.6808 0.88131 23.1187C0.319214 22.5566 0.00237409 21.7949 0 21V4.5C0.00237409 3.70508 0.319214 2.94338 0.88131 2.38129C1.4434 1.81919 2.20508 1.50237 3 1.5H6C6 1.10218 6.15803 0.720666 6.43934 0.439362C6.72064 0.158057 7.10218 0 7.5 0C7.89782 0 8.27936 0.158057 8.56066 0.439362C8.84197 0.720666 9 1.10218 9 1.5H15C15 1.10218 15.158 0.720666 15.4393 0.439362C15.7206 0.158057 16.1022 0 16.5 0C16.8978 0 17.2794 0.158057 17.5607 0.439362C17.842 0.720666 18 1.10218 18 1.5H21C21.7949 1.50237 22.5566 1.81919 23.1187 2.38129C23.6808 2.94338 23.9976 3.70508 24 4.5V10.5C24 10.8978 23.842 11.2793 23.5607 11.5606C23.2794 11.8419 22.8978 12 22.5 12C22.1022 12 21.7206 11.8419 21.4393 11.5606C21.158 11.2793 21 10.8978 21 10.5H3V21H10.5C10.8978 21 11.2794 21.1581 11.5607 21.4394C11.842 21.7207 12 22.1022 12 22.5C12 22.8978 11.842 23.2793 11.5607 23.5606C11.2794 23.8419 10.8978 24 10.5 24H3ZM3 7.5H21V4.5H18C18 4.89782 17.842 5.27933 17.5607 5.56064C17.2794 5.84194 16.8978 6 16.5 6C16.1022 6 15.7206 5.84194 15.4393 5.56064C15.158 5.27933 15 4.89782 15 4.5H9C9 4.89782 8.84197 5.27933 8.56066 5.56064C8.27936 5.84194 7.89782 6 7.5 6C7.10218 6 6.72064 5.84194 6.43934 5.56064C6.15803 5.27933 6 4.89782 6 4.5H3V7.5Z" fill="#DF1721" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9 16.8001C23.5628 17.2971 23.6971 18.2373 23.2 18.9001L19.45 23.9001C19.2014 24.2316 18.8267 24.4455 18.4148 24.491C18.0028 24.5365 17.5905 24.4096 17.2754 24.1403L15.0254 22.2173C14.3957 21.679 14.3215 20.7322 14.8598 20.1024C15.398 19.4727 16.3449 19.3985 16.9746 19.9368L18.0093 20.8211L20.8 17.1001C21.2971 16.4373 22.2373 16.303 22.9 16.8001Z" fill="#DF1721" />
  </svg>
);

export const DocumentAddedIcon = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.9994 22.5001V21.0001H16.4995C16.1017 21.0001 15.7201 20.8421 15.4389 20.5608C15.1576 20.2795 14.9995 19.898 14.9995 19.5002C14.9995 19.1023 15.1576 18.7208 15.4389 18.4395C15.7201 18.1582 16.1017 18.0002 16.4995 18.0002H17.9994V16.5002C17.9994 16.1024 18.1575 15.7209 18.4388 15.4396C18.7201 15.1583 19.1016 15.0003 19.4994 15.0003C19.8972 15.0003 20.2787 15.1583 20.56 15.4396C20.8413 15.7209 20.9993 16.1024 20.9993 16.5002V18.0002H22.4993C22.8971 18.0002 23.2786 18.1582 23.5599 18.4395C23.8412 18.7208 23.9992 19.1023 23.9992 19.5002C23.9992 19.898 23.8412 20.2795 23.5599 20.5608C23.2786 20.8421 22.8971 21.0001 22.4993 21.0001H20.9993V22.5001C20.9993 22.8979 20.8413 23.2794 20.56 23.5607C20.2787 23.842 19.8972 24 19.4994 24C19.1016 24 18.7201 23.842 18.4388 23.5607C18.1575 23.2794 17.9994 22.8979 17.9994 22.5001ZM2.99991 24C2.20501 23.9976 1.44336 23.6808 0.881282 23.1187C0.319204 22.5567 0.00237402 21.795 0 21.0001V3.00067C0.00237402 2.20578 0.319204 1.4441 0.881282 0.882026C1.44336 0.319948 2.20501 0.00314121 2.99991 0.000767192H11.9996C12.0999 -0.00206851 12.2002 0.0029613 12.2996 0.0157814C15.3741 0.0932891 18.3014 1.34921 20.4761 3.52393C22.6508 5.69864 23.9067 8.62584 23.9843 11.7004C23.997 11.7998 24.0021 11.9002 23.9992 12.0004H11.9996V3.00067H2.99991V21.0001H10.4997C10.8975 21.0001 11.279 21.1582 11.5603 21.4394C11.8416 21.7207 11.9996 22.1022 11.9996 22.5001C11.9996 22.8979 11.8416 23.2794 11.5603 23.5607C11.279 23.842 10.8975 24 10.4997 24H2.99991ZM14.9995 9.00048H20.4743C20.031 7.73563 19.3087 6.58678 18.361 5.63905C17.4133 4.69131 16.2644 3.96901 14.9995 3.52566V9.00048Z" fill="#DF1721" />
  </svg>
);
