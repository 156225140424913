import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { Button } from 'components';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import WorkspaceContext from 'workspace/WorkspaceContext';
import { getTransactions } from 'api/billing';
import { Transaction } from 'views/TransactionsPage/transactions.d';
import RequestPaymentForm from 'components/RequestPaymentForm';
import dayjs from 'dayjs';
import { formatCurrency } from 'utils/rifmFormatters';
import useLayout from 'hooks/useLayout';
import pluralize from 'pluralize';

const WorkspacePaymentsTab: React.FC = () => {
  const layout = useLayout();
  const { listing, listingId } = useContext(WorkspaceContext);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [requestPaymentFormIsOpen, setRequestPaymentFormIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { transactions } = await getTransactions({ listingId });
        setTransactions(transactions);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [listingId]);

  if (requestPaymentFormIsOpen)
    return (
      <StyledRequestPaymentForm
        listing={listing}
        onReturn={() => setRequestPaymentFormIsOpen(false)}
        onSuccess={newTransaction =>
          setTransactions(transactions => [...transactions, newTransaction])
        }
      />
    );

  return (
    <StyledWorkspacePaymentsTab>
      <div className="header-section">
        <h3 className="title">
          {transactions.length} {pluralize('payment', transactions.length)}
        </h3>
        <Button onClick={() => setRequestPaymentFormIsOpen(true)}>+ Request Payment</Button>
      </div>
      {layout === 'mobile' ? (
        <ul className="mobile-list">
          {transactions.map(transaction => (
            <li key={transaction.id} className={clsx({ pending: !transaction.charged })}>
              <div>
                <span className="title">Date</span>
                {dayjs(transaction.date).format('MM/DD/YYYY hh:mm A')}
              </div>
              <div>
                <span className="title">Name</span>
                {transaction.name}
              </div>
              <div>
                <span className="title">Amount</span>
                {formatCurrency(transaction.amount)}
              </div>
              <div>
                <span className="title">Commission</span>
                {formatCurrency(transaction.commission)}
              </div>
              <div>
                <span className="title">Payer</span>
                {transaction.payer}
              </div>
              <div>
                <span className="title">Receipt Number</span>
                {transaction.charged ? transaction.receiptNumber : 'Pending'}
              </div>
            </li>
          ))}
        </ul>
      ) : (
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Commisson</th>
                <th>Payer</th>
                <th>Receipt Number</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map(transaction => (
                <tr key={transaction.id} className={clsx({ pending: !transaction.charged })}>
                  <td>{dayjs(transaction.date).format('MM/DD/YYYY hh:mm A')}</td>
                  <td>{transaction.name}</td>
                  <td>{formatCurrency(transaction.amount)}</td>
                  <td>{formatCurrency(transaction.commission)}</td>
                  <td>{transaction.payer}</td>
                  <td>{transaction.charged ? transaction.receiptNumber : 'Pending'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </StyledWorkspacePaymentsTab>
  );
};

export default WorkspacePaymentsTab;

const StyledWorkspacePaymentsTab = styled.div`
  margin-top: 32px;

  .header-section {
    display: flex;
    justify-content: space-between;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    th,
    td {
      padding: 20px 20px 20px 0;

      &:last-of-type {
        padding-right: 0;
      }
    }

    th {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
      font-weight: normal;
    }

    td {
      font-size: 14px;
      border-top: 1px solid ${props => props.theme.colors.borderColor};
    }

    tr.pending td {
      &:nth-of-type(3),
      &:nth-of-type(6) {
        color: ${props => props.theme.colors.grayDark};
      }
    }
  }

  .mobile-list {
    list-style: none;
    padding: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    margin: 0;

    li {
      padding: 20px 0;
      border-bottom: 1px solid ${props => props.theme.colors.borderColor};

      &:last-of-type {
        border-bottom: none;
      }

      & > div {
        margin: 16px 0;
        white-space: nowrap;

        .title {
          display: inline-block;
          width: 120px;
          margin-right: 32px;
          color: ${props => props.theme.colors.grayDark};
        }
      }
    }

    li.pending > div {
      &:nth-of-type(3),
      &:nth-of-type(6) {
        color: ${props => props.theme.colors.grayDark};
      }
    }
  }
`;

const StyledRequestPaymentForm = styled(RequestPaymentForm)`
  .form-grid {
    margin-bottom: 40px;
  }
  .form-controls {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #f8f8f8;
    height: 48px;
    padding: 8px 20px;
  }
`;
