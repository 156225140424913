import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import Button from 'components/Button';
import Input from 'components/Input';
import { InputDate } from '@xchange/uikit';
import { CalendarItem } from 'views/CalendarPage/calendar.d';
import WorkspaceContext from 'workspace/WorkspaceContext';

interface EditDeadlineFormProps {
  initialData?: Partial<CalendarItem>;
  onSubmitNew: (values) => void;
  onSubmitEdit: (values) => void;
}

const EditDeadlineForm: React.FC<EditDeadlineFormProps> = ({
  initialData,
  onSubmitNew,
  onSubmitEdit
}) => {
  const { listingId } = useContext(WorkspaceContext);
  const restrictEdit = Boolean(initialData?.id) && !initialData!.editable;
  const {
    register,
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      ...initialData,
      propertyId: listingId,
      dateType: 'deadline',
      date: initialData?.start?.format('MM/DD/YYYY')
    }
  });
  const isEditing = Boolean(initialData?.id);

  const submit = form => {
    if (isEditing) onSubmitEdit({ ...form, id: initialData?.id });
    else onSubmitNew(form);
  };

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      <Input
        {...register('title', { required: 'Required' })}
        error={errors.title?.message}
        label="Title"
        placeholder="Enter"
        disabled={isEditing}
        data-cy="input_title"
      />
      <Controller
        control={control}
        rules={{
          required: 'Required',
          pattern: {
            value: /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/,
            message: 'Invalid date'
          }
        }}
        name="date"
        defaultValue={dayjs().format('MM/DD/YYYY')}
        render={({ field, formState: { errors } }) => (
          <InputDate
            inputProps={{
              label: 'Date',
              error: errors.date?.message,
              disabled: restrictEdit,
              'data-cy': 'input_date'
            }}
            {...field}
          />
        )}
      />
      <Button type="submit" data-cy="button_submit">
        {isEditing ? 'Edit' : 'Add new'}
      </Button>
    </StyledForm>
  );
};

export default EditDeadlineForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 16px;
  }

  & > .button {
    height: 40px;
  }

  .optional {
    color: ${props => props.theme.colors.grayDark};
  }
`;
