/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import { Button, Tabs, SideMenu, Icon, Accordion, InputEdit } from '@xchange/uikit';
import { Image, ConfirmationDialog, NoSSR } from 'components';
import { getListingAddress, getListingImageProps, isMlsListing } from 'utils/listing';
import useAuthContext from 'hooks/useAuthContext';
import WorkspaceContext, { WorkspaceContextWrapper } from './WorkspaceContext';
import WorkspaceDocumentsTab from './WorkspaceDocumentsTab';
// import WorkspaceOffersTab from './WorkspaceOffersTab';
import WorkspaceMembersTab from './WorkspaceMembersTab';
import WorkspaceTasksTab from './WorkspaceTasksTab';
import WorkspaceNotesTab from './WorkspaceNotesTab';
import WorkspaceActivityTab from './WorkspaceActivityTab';
import WorkspacePaymentsTab from './WorkspacePaymentsTab';
import WorkspaceTransactionsTab from './WorkspaceTransactionsTab';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const tabs = [
  { key: 'documents', text: 'Documents' },
  { key: 'transactions', text: 'Transaction Details' },
  // { key: 'offers', text: 'Offers' },
  { key: 'members', text: 'Members' },
  { key: 'tasks', text: 'Tasks' },
  { key: 'activity', text: 'Activity' },
  { key: 'payments', text: 'Payments' },
  { key: 'notes', text: 'Notes' }
];

const Workspace = React.memo(() => {
  const {
    listing,
    members,
    workspaceOpen,
    closeWorkspace,
    workspaceTab,
    setWorkspaceTab,
    deleteListing,
    addListingMlsId
  } = useContext(WorkspaceContext);
  const { authData } = useAuthContext();
  const showBuyerSellerInfo = members.length !== 0;
  const [openDeleteListing, setOpenDeleteListing] = useState<boolean>(false);
  const [editMlsId, setEditMlsId] = useState<boolean>(false);
  const layout = useLayout();
  const { status } = listing || {} as Listing;
  const isUnderContractOrClosed = ['Under Contract', 'Closed'].includes(status);
  const isAdmin = authData?.admin;

  const visibleTabs = useMemo(() => {
    let filteredTabs = [...tabs];
    if (!isUnderContractOrClosed) {
      filteredTabs = filteredTabs.filter(tab => tab.key !== 'transactions');
    }
    if (!isAdmin) {
      filteredTabs = filteredTabs.filter(tab => tab.key !== 'payments');
    }
    return filteredTabs;
  }, [status, isAdmin]);

  const saveMlsId = async value => {
    await addListingMlsId(value);
    setEditMlsId(false);
  };

  const tabContent = useMemo(() => {
    switch (workspaceTab) {
      case 'documents':
        return <WorkspaceDocumentsTab />;
      // case 'offers':
      //   return <WorkspaceOffersTab />;
      case 'members':
        return <WorkspaceMembersTab />;
      case 'tasks':
        return <WorkspaceTasksTab />;
      case 'activity':
        return <WorkspaceActivityTab />;
      case 'payments':
        return <WorkspacePaymentsTab />;
      case 'transactions':
        return <WorkspaceTransactionsTab />;
      case 'notes':
        return <WorkspaceNotesTab />;
      default:
        return null;
    }
  }, [workspaceTab]);

  const contactPerson: SellBuySide | ListingAgent = useMemo(() => {
    if (!listing) return {} as SellBuySide;
    var cPerson = null;
    if( listing.type === "Sell"){
      cPerson = listing.seller || listing.sellerBroker || listing.listingAgent;
    }else{
      cPerson =  listing.buyer || listing.buyerBroker;
    }

    return cPerson ? cPerson : {}
    
  }, [listing]);

  if (!listing) return null;

  const isBroker = !listing.seller && !listing.buyer;

  const contactName = contactPerson.name;
  const contactEmail = Array.isArray(contactPerson.email)
    ? contactPerson.email
    : [contactPerson.email];
  const contactPhone =
    (contactPerson as SellBuySide).phone || (contactPerson as ListingAgent).mobilePhone;
  const contactBusinessPhone =
    (contactPerson as SellBuySide).businessPhone ||
    (contactPerson as ListingAgent).officePhone;

  const firstImageUrl = listing.images?.[0];
  const imageProps = getListingImageProps(firstImageUrl);

  const brokerSellerInfo = (
    <>
      <div className="top-section__info-block-row name">
        {contactName && (
          <>
            <Icon name="user" />
            {contactName}&nbsp;
            {isBroker && `(Broker)`}
          </>
        )}
      </div>
      {contactEmail.map(email => (
        <div key={email} className="top-section__info-block-row email">
          <Icon name="email" />
          <a href={`mailto:${email}`} className="link">
            {email}
          </a>
        </div>
      ))}
      {contactBusinessPhone && (
        <div className="top-section__info-block-row phone">
          <Icon name="phone" />{' '}
          <a href={`tel:${contactBusinessPhone}`} className="link">
            {contactBusinessPhone}
          </a>
        </div>
      )}
      {contactPhone && (
        <div className="top-section__info-block-row phone">
          <Icon name="smartphone" />{' '}
          <a href={`tel:${contactPhone}`} className="link">
            {contactPhone}
          </a>
        </div>
      )}
    </>
  );

  return (
    <StyledWorkspace className="workspace" open={workspaceOpen} onClose={closeWorkspace}>
      <div className="top-section">
        <div className="top-section__property">
          <Image size="248x248" className="top-section__photo" {...imageProps} />
          <div className="top-section__info-block">
            <div className="top-section__info-block-row address">{getListingAddress(listing)}</div>
            <div className="top-section__info-block-row price">
              {currencyFormatter.format(listing.currentPrice)}
            </div>
            <div className="top-section__info-block-row id">
              MLS ID:&nbsp;
              <span className="id__value">
                {!isMlsListing(listing?.listingId) ? (
                  !editMlsId ? (
                    <>
                      Not set&nbsp;
                      <Button simple onClick={() => setEditMlsId(true)}>
                        <Icon name="pen" />
                      </Button>
                    </>
                  ) : (
                      <InputEdit onSave={saveMlsId} onCancel={() => setEditMlsId(false)} />
                    )
                ) : (
                    listing?.listingId
                  )}
              </span>
            </div>
            {layout === 'mobile' && showBuyerSellerInfo && (
              <Accordion
                className="top-section__info-accordion"
                label="Broker / Seller Info"
                icon={<Icon name="chevron" size={16} />}>
                {brokerSellerInfo}
              </Accordion>
            )}
          </div>
        </div>
        <div className="top-section__info-block">
          {layout !== 'mobile' && showBuyerSellerInfo && brokerSellerInfo}
        </div>
        <Button secondary className="delete-btn" onClick={() => setOpenDeleteListing(true)}>
          <Icon name="trash" />
        </Button>
      </div>
      <StyledTabs
        activeTab={workspaceTab!}
        onSelect={(_e, value: any) => setWorkspaceTab(value.key)}
        items={visibleTabs}
      />
      {tabContent}
      <ConfirmationDialog
        modalTitle="Delete Listing"
        open={openDeleteListing}
        buttonLabels={{ submit: 'Delete' }}
        onSubmit={() => {
          deleteListing(listing.id, () => window.location.reload());
        }}
        onClose={() => setOpenDeleteListing(false)}>
        <p>
          Are you sure you want to delete this listing? All listing data will be lost. This action
          can’t be undone.
        </p>
      </ConfirmationDialog>
    </StyledWorkspace>
  );
});

export default ({ children }) => (
  <WorkspaceContextWrapper>
    <NoSSR>
      <Workspace />
    </NoSSR>
    {children}
  </WorkspaceContextWrapper>
);

const StyledWorkspace = styled(SideMenu)`
  & > .side-menu {
    display: flex;
    flex-direction: column;
    width: 1024px;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    margin: 0 0 24px;
    flex-shrink: 0;

    .delete-btn {
      width: 24px;
      height: 24px;
      margin-top: auto;
      padding: 0;
      align-self: flex-start;
    }

    &__property {
      display: flex;
      flex: 1;
    }

    &__info-block {
      display: flex;
      flex-direction: column;
      margin-right: 66px;
      font-size: 14px;
      line-height: 16px;
    }
    &__item-img {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
      flex-shrink: 0;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__info-block-row {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      &.address {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
      }
      &.price {
        color: ${props => props.theme.colors.grayDark};
      }
      &.id {
        font-weight: 500;
        .id__value {
          display: inline-flex;
          color: ${props => props.theme.colors.grayDark};
          font-weight: normal;
        }
        .icon-pen {
          fill: ${props => props.theme.colors.red};
        }
      }
      &.email,
      &.phone,
      &.phone {
        color: ${props => props.theme.colors.mineShaft};
        .icon {
          margin: 0 12px 0 0;
          flex-shrink: 0;
        }
      }
      &.name {
        font-weight: 500;
        .icon {
          margin: 0 12px 0 0;
        }
      }
      .link {
        text-decoration: none;
        color: unset;
      }
      .email .link {
        width: min-content;
      }
    }

    &__photo {
      width: 124px;
      height: 124px;
      object-fit: cover;
      margin-right: 20px;
      border-radius: ${props => props.theme.misc.borderRadius};
    }
    &__info-label {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.darkGray};
      margin: 0 0 8px;
      display: block;
    }
    &__info-accordion {
      .accordion-head {
        margin-bottom: 8px;
        padding: 0;
        display: inline-block;
        color: ${props => props.theme.colors.red};
        fill: ${props => props.theme.colors.red};

        .icon {
          float: right;
        }
      }

      &.open .accordion-head .icon {
        transform: rotate(180deg);
      }
    }
  }

  .not-found-message {
    font-size: 16px;
    line-height: 24px;
    color: #8b8b8b;
    text-align: center;
    margin: 64px 0 0;
    &.notes {
      margin: 8px 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .tab-item {
      padding: 6px;
      margin-right: 6px;
      flex-shrink: 0;
    }
    .top-section {
      flex-direction: column;
      justify-content: flex-start;
      &__info-block {
        margin-right: 0;
      }
      &__photo {
        width: 80px;
        height: 80px;
      }
      &__property {
        margin: 0 32px 24px 0;
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  flex-shrink: 0;
  flex-wrap: nowrap;
  .tab-item {
    width: 100%;
    font-weight: 600;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    overflow-x: auto;
    .tab-item {
      width: auto;
    }
  }
`;
