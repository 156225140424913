import { useState, useEffect, useMemo } from 'react';
import { groupBy } from 'lodash';
import { DropdownOption } from '@xchange/uikit';
import { getDocumentTypes } from 'api/documents';

export default function useDocumentTypes() {
  const [documentTypes, setDocumentTypes] = useState<WorkspaceDocumentType[]>([]);

  const getDocumentTypeOptions = (): DropdownOption[] => {
    let options: DropdownOption[] = [];
    const groups: { [key: string]: WorkspaceDocumentType[] } = groupBy(documentTypes, 'category');
    const orderedGroups: { [key: string]: WorkspaceDocumentType[] } = Object.keys(groups)
      .sort((a, _) => (a === 'Listing Documents' ? -1 : 1))
      .reduce((obj, key) => {
        obj[key] = groups[key];
        return obj;
      }, {});
    Object.entries(orderedGroups).forEach(([groupKey, items]) => {
      options.push({
        value: groupKey,
        text: `--- ${groupKey} ---`,
        disabled: true,
        noSearch: true
      });
      // }
      options = [
        ...options,
        ...items.map((item: DocumentType) => ({
          value: item.id,
          text: item.name
        }))
      ];
    });
    return options;
  };

  const documentTypeOptions = useMemo(() => getDocumentTypeOptions(), [documentTypes]);

  useEffect(() => {
    (async () => {
      try {
        const documentTypes = await getDocumentTypes();
        setDocumentTypes(documentTypes);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return {
    documentTypes,
    documentTypeOptions
  };
}
