import { fetch } from 'utils/fetch';
import { mapListings } from 'utils/mapping';
import { urlEncodeBody } from 'utils/requests';

export async function getListing(id: string): Promise<Listing> {
  const response = await fetch(`/listings/listing/${id}`);
  const data = await response.json();
  return mapListings([data])[0] as Listing;
}

export async function getListingsAddresses(params?: {
  search?: string;
}): Promise<{ id: string; propertyAddress: string }[]> {
  const response = await fetch(`/listings/addresses`, { query: urlEncodeBody(params).toString() });
  const data = await response.json();
  return data.map(item => ({ id: item.listing_id, propertyAddress: item.propertyAddress }));
}
