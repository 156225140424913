/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import MaskedInput from 'react-input-mask';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';

import { disablePastDates } from 'utils/date';
import { REGEXP } from 'consts';
import { Button, RadioButton, Input, Checkbox, InputDate } from '@xchange/uikit';
import { InputCurrency, InputCurrencyDecimal } from 'components';
import stepFinancialSchema from './data/stepFinancialSchema.json';
import { StepFinancialsData } from './types';

const STEPS_NUMBER = 2;
interface StepFinancialsProps {
  defaultValues?: { [key: string]: any; };
  onSubmit: (values: Partial<StepFinancialsData>) => void;
}

const StepFinancials: React.FC<StepFinancialsProps> = ({ defaultValues, onSubmit }) => {
  const [step, setStep] = useState(0);
  const {
    register,
    control,
    formState: { errors },
    watch,
    handleSubmit
  } = useForm({
    defaultValues
  });
  const { fields: sellers, append } = useFieldArray({
    control,
    name: 'sellers'
  });
  const isPropertyLeased = watch('property_leased');
  const hasErrors = Object.keys(errors).length > 0;

  if (sellers.length === 0) {
    append({
      name: defaultValues?.seller?.name,
      email: defaultValues?.seller?.email,
      phone: defaultValues?.seller?.phone
    });
  }

  const handleContinue = values => {
    if (hasErrors) return;
    if (step < STEPS_NUMBER) setStep(step + 1);
    else onSubmit(values);
  };

  const onAddField = e => {
    e.preventDefault();
    append({});
  };

  return (
    <form className="process-form" onSubmit={handleSubmit(handleContinue)}>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          rules={{
            required: 'Required'
          }}
          name="current_price"
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Listing Price*"
              placeholder="Enter price"
              min={0}
              error={errors.current_price?.message}
              className="process-form__input"
              {...field}
            />
          )}
        />
      </div>
      <h3 className="process-form__title seller-title">Seller Info:</h3>
      {sellers.map((item, index) => (
        <div className="process-form__input-block seller-inputs" key={`sellers[${index}]`}>
          <Input
            {...register(`sellers[${index}].name`, {
              pattern: { value: REGEXP.NAME, message: 'Invalid name' }
            })}
            defaultValue={(item as any).name}
            id={`sellers[${index}].name`}
            error={errors.sellers?.[index]?.name?.message}
            className="process-form__input"
            label="Name*"
            placeholder="Enter name"
          />
          <Input
            {...register(`sellers[${index}].email`, {
              pattern: { value: REGEXP.EMAIL, message: 'Invalid email address' }
            })}
            defaultValue={(item as any).email}
            id={`sellers[${index}].email`}
            name={`sellers[${index}].email`}
            error={errors.sellers?.[index]?.email?.message}
            className="process-form__input"
            label="Email*"
            placeholder="Enter email"
          />
          <Controller
            control={control}
            name={`sellers[${index}].phone`}
            rules={{
              validate: value => !value?.includes('_') || 'Required'
            }}
            defaultValue={(item as any).phone}
            render={({ field, formState: { errors } }) => (
              <Input
                id={`sellers[${index}].phone`}
                className="process-form__input"
                as={MaskedInput}
                mask="+1 (999) 999-9999"
                type="tel"
                error={errors.sellers?.[index]?.phone?.message}
                label="Phone Number*"
                placeholder="Enter phone number"
                {...field}
              />
            )}
          />
        </div>
      ))}
      <div className="info-block__btn-block info-block__btn-block_add">
        <Button className="info-block__btn info-block__btn-add" onClick={onAddField}>
          + Add Seller
        </Button>
      </div>
      <h3 className="process-form__title">Listing Dates</h3>
      <div className="process-form__input-block process-form__input-block">
        <Controller
          control={control}
          rules={{
            required: 'Required',
            validate: value => dayjs(value).isValid() || `Invalid date`
          }}
          name="listing_date_termination"
          render={({ field }) => (
            <InputDate
              inputProps={{
                label: 'Listing Termination Date*',
                placeholder: 'MM/DD/YYYY',
                error: errors.listing_date_termination?.message
              }}
              isValidDate={disablePastDates}
              className="process-form__input"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          rules={{
            validate: value => dayjs(value).isValid() || `Invalid date`
          }}
          name="start_listing_earlier"
          render={({ field }) => (
            <InputDate
              inputProps={{
                label: 'Start Listing not earlier than',
                placeholder: 'MM/DD/YYYY',
                error: errors.start_listing_earlier?.message
              }}
              isValidDate={disablePastDates}
              className="process-form__input"
              {...field}
            />
          )}
        />
      </div>
      <h3 className="process-form__title">Fixed Marketing Costs</h3>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          name="marketing_cost"
          defaultValue={defaultValues?.package_price_total?""+defaultValues?.package_price_total:""}
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Upfront Flat Fee ($)"
              placeholder="Enter upfront flat fee"
              min={0}
              error={errors.marketing_cost?.message}
              className="process-form__input"
              {...field}
            />
          )}
        />
      </div>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          name="sale_commission"
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <Input
              label="Total Broker Commissions (%)"
              placeholder="Enter total broker commissions"
              min={0}
              error={errors.sale_commission?.message}
              className="process-form__input"
              {...field}
            />
          )}
        />
      </div>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          name="coop_commission"
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <Input
              label="Coop Commissions (%)"
              placeholder="Enter coop commissions"
              min={0}
              error={errors.coop_commission?.message}
              className="process-form__input"
              {...field}
            />
          )}
        />
      </div>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          name="additional_sale_commission"
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Additional Commissions ($)"
              placeholder="Enter additional commissions"
              min={0}
              error={errors.additional_sale_commission?.message}
              className="process-form__input"
              {...field}
            />
          )}
        />
      </div>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          name="reimbursement"
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="Early Termination Fee ($)"
              placeholder="Enter early termination fee"
              min={0}
              error={errors.reimbursement?.message}
              className="process-form__input"
              {...field}
            />
          )}
        />
      </div>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          name="mls_fee"
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <InputCurrency
              label="MLS Fee ($)"
              placeholder="Enter MLS fee"
              min={0}
              error={errors.mls_fee?.message}
              className="process-form__input"
              {...field}
            />
          )}
        />
      </div>
      <h3 className="process-form__title">Dual Agency</h3>
      <div className="process-form__input-block">
        <div className="process-form__radio-block">
          <RadioButton {...register('dual_agency')} value="Yes" label="Yes" />
          <RadioButton {...register('dual_agency')} defaultChecked value="No" label="No" />
        </div>
      </div>
      {/* *******end of step 1******* */}
      {step > 0 && (
        <div className="process-form__step">
          <div className="process-form__input-block">
            <Controller
              control={control}
              name="pin"
              rules={{
                validate: value => !String(value).includes('_') || 'Invalid PIN'
              }}
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="process-form__input"
                  as={MaskedInput}
                  mask="99-99-999-999-9999"
                  error={errors.pin?.message}
                  label="PIN"
                  placeholder="Enter PIN"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="last_year_taxes"
              defaultValue=""
              render={({ field }) => (
                <InputCurrencyDecimal
                  label="Last Year Taxes"
                  placeholder="Enter last year taxes"
                  min={0}
                  className="process-form__input"
                  {...field}
                />
              )}
            />
          </div>

          {/*<div className="process-form__input-block">
            <Input
              {...register('electronic_account_number')}
              className="process-form__input"
              label="Electronic Account Number"
              placeholder="Electronic Account Number"
            />
            <Input
              {...register('gas_account_number')}
              className="process-form__input"
              label="Gas Account Number"
              placeholder="Gas Account Number"
            />
            </div>*/}

          <h3 className="process-form__title">Property Leased</h3>
          <div className="process-form__radio-block">
            <RadioButton {...register('property_leased')} value="Yes" label="Yes" />
            <RadioButton
              {...register('property_leased')}
              defaultChecked={!defaultValues?.property_leased}
              value="No"
              label="No"
            />
          </div>
          {isPropertyLeased === 'Yes' && (
            <div className="process-form__input-block process-form__input-block_short">
              <Controller
                control={control}
                rules={{
                  required: isPropertyLeased === 'Yes' ? 'Required' : false,
                  validate: value => dayjs(value).isValid() || `Invalid date`
                }}
                name="lease_expiry_date"
                render={({ field }) => (
                  <InputDate
                    inputProps={{
                      label: 'Lease Expiry Date*',
                      placeholder: 'MM/DD/YYYY',
                      error: errors.lease_expiry_date?.message
                    }}
                    isValidDate={disablePastDates}
                    className="process-form__input"
                    {...field}
                  />
                )}
              />
            </div>
          )}
        </div>
      )}
      {/* *******end of step 1******* */}
      {/* *******beginning of step 2******* */}
      {step > 1 && (
        <div className="process-form__step">
          <div className="checkbox-wrapper">
            <div className="checkbox-section">
              <div className="checkbox-container">
                <div className="checkbox-container__title">Price Includes</div>
                <div className="checkboxes">
                  {Object.keys(stepFinancialSchema.price_includes).map(key => (
                    <Checkbox
                      {...register(key)}
                      label={stepFinancialSchema.price_includes[key].label}
                      key={key}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="process-form__input-block">
            <Input
              {...register('seller_transfers')}
              className="process-form__input"
              label="Seller also transfers the following"
              placeholder="Enter text"
            />
            <Input
              {...register('items_excluded')}
              className="process-form__input"
              label="The following items are excluded"
              placeholder="Enter items"
            />
          </div>
        </div>
      )}
      {/* *******end of step 2******* */}
      <Button className="process-form__btn">{step < STEPS_NUMBER ? 'Continue' : 'Next'}</Button>
    </form>
  );
};

export default StepFinancials;
