import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { Button, Checkbox, Dropdown, Input, InputDate, RadioButton } from '@xchange/uikit';
import { FileInput } from 'components';
import { disablePastDates, disableFutureDates } from 'utils/date';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { uploadFileToS3 } from 'api/documents';
import stepPropertyDetailsSchema from './data/stepPropertyDetailsSchema.json';
import { StepPropertyDetailsData } from './types';

const currentYear = dayjs().year();
interface StepPropertyDetailsProps {
  defaultValues?: { [key: string]: any; };
  condo?: boolean;
  townhouse?: boolean;
  multifamily?: boolean;
  listingId: any;
  onSubmit: (values: StepPropertyDetailsData) => void;
}

const StepPropertyDetails: React.FC<StepPropertyDetailsProps> = ({
  defaultValues,
  condo,
  townhouse,
  multifamily,
  listingId,
  onSubmit
}) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    handleSubmit
  } = useForm({
    defaultValues
  });
  const [showMore, setShowMore] = useState(false);
  const hasErrors = Object.keys(errors).length > 0;

  const onFileUpload = async fileToAdd => {
    try {
      const { url, documentId } = await uploadFileToS3(fileToAdd, {
        publicAccess: true,
        listingId,
        doctype: 'Listing Photos'
      });
      const files = getValues('images') || [];
      setValue('images', [...files, { id: documentId, url, name: fileToAdd.name }]);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const onFileRemove = (indexToRemove: number) => {
    const files = [...getValues('images')];
    files.splice(indexToRemove, 1);
    setValue('images', files);
  };

  const getFilesLabel = files => {
    const { length } = files;
    if (!length) return 'Upload';
    return `${length} ${pluralize('file', length)}`;
  };

  const handleContinue = values => {
    if (hasErrors) return;
    if (!showMore) setShowMore(true);
    else onSubmit(values);
  };

  return (
    <form className="process-form" onSubmit={handleSubmit(handleContinue)}>
      <h3 className="process-form__title">My Property is:</h3>
      <div className="process-form__input-block">
        {/* <Controller
          control={control}
          rules={{
            required: 'Date required'
          }}
          name="available_on"
          render={({ field }) => (
            <InputDate
              inputProps={{
                label: 'Available on*',
                placeholder: 'MM/DD/YYYY',
                error: errors.available_on?.message
              }}
              isValidDate={disablePastDates}
              className="process-form__input"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="purchased_on"
          render={({ field }) => (
            <InputDate
              inputProps={{
                label: 'Purchased on',
                placeholder: 'MM/DD/YYYY',
                error: errors.purchased_on?.message
              }}
              initialViewMode="years"
              initialViewDate={new Date(currentYear - 10, 0, 0)}
              isValidDate={disableFutureDates}
              className="process-form__input"
              {...field}
            />
          )}
        /> */}
        <Input
          {...register('community')}
          label="Community"
          placeholder="Community"
          className="process-form__input hide_temp"
        />
        <Input
          {...register('property_tax_id')}
          label="Property tax ID"
          placeholder="Enter property tax ID"
          className="process-form__input hide_temp"
        />
      </div>
      <div className="process-form__textarea-block">
        <Input
          as="textarea"
          {...register('property_description')}
          label="Property Description"
          placeholder="Enter property description"
          className="process-form__textarea hide_temp"
        />
      </div>
      <div className="process-form__input-block process-form__input-block_full-width hide_temp">
        <Controller
          control={control}
          name="images"
          defaultValue=""
          render={({ field: { onChange, value, ...field } }) => (
            <FileInput
              label="Upload photos"
              placeholder={getFilesLabel(value)}
              onFileRemove={onFileRemove}
              files={value}
              onChange={onFileUpload}
              showSpinner
              {...field}
            />
          )}
        />
      </div>
      {showMore && (
        <>
          <div className="checkbox-wrapper hide_temp">
            <div className="checkbox-section">
              <div className="checkbox-container">
                <div className="checkbox-container__title">Appliances</div>
                <div className="checkboxes checkboxes-col2">
                  {Object.keys(stepPropertyDetailsSchema.appliances).map(key => (
                    <Checkbox
                      {...register(key)}
                      label={stepPropertyDetailsSchema.appliances[key].label}
                      key={key}
                    />
                  ))}
                </div>
              </div>
              <div className="checkbox-section">
                <div className="checkbox-container">
                  <div className="checkbox-container__title">Laundry</div>
                  <div className="checkboxes">
                    {stepPropertyDetailsSchema.laundry.enum.map((item, index) => (
                      <RadioButton
                        {...register('laundry')}
                        label={item}
                        value={item}
                        id={`laundry[${index}]`}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`laundry[${index}]`}
                      />
                    ))}
                  </div>
                </div>
                <div className="checkbox-container">
                  <div className="checkbox-container__title">Pets</div>
                  <div className="checkboxes">
                    {Object.keys(stepPropertyDetailsSchema.pets).map(key => (
                      <Checkbox
                        {...register(key)}
                        label={stepPropertyDetailsSchema.pets[key].label}
                        key={key}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="checkbox-wrapper hide_temp">
            <div className="checkbox-section">
              <div className="checkbox-container">
                <div className="checkbox-container__title">Indoor Features</div>
                <div className="checkboxes checkboxes-col2">
                  {Object.keys(stepPropertyDetailsSchema.indoor_features).map(key => (
                    <Checkbox
                      {...register(key)}
                      label={stepPropertyDetailsSchema.indoor_features[key].label}
                      key={key}
                    />
                  ))}
                </div>
              </div>
              <div className="checkbox-section">
                <div className="checkbox-container">
                  <div className="checkbox-container__title">Outdoor Features</div>
                  <div className="checkboxes checkboxes-col2">
                    {Object.keys(stepPropertyDetailsSchema.outdoor_features).map(key => (
                      <Checkbox
                        {...register(key)}
                        label={stepPropertyDetailsSchema.outdoor_features[key].label}
                        key={key}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="checkbox-wrapper hide_temp">
            <div className="checkbox-section">
              <div className="checkbox-container">
                <div className="checkbox-container__title">Facilities & Recreation</div>
                <div className="checkboxes checkboxes-col2">
                  {Object.keys(stepPropertyDetailsSchema.facilities_and_recreation).map(key => (
                    <Checkbox
                      {...register(key)}
                      label={stepPropertyDetailsSchema.facilities_and_recreation[key].label}
                      key={key}
                    />
                  ))}
                </div>
              </div>
              <div className="checkbox-section">
                <div className="checkbox-container">
                  <div className="checkbox-container__title">Access & Security</div>
                  <div className="checkboxes checkboxes-col2">
                    {Object.keys(stepPropertyDetailsSchema.access_and_security).map(key => (
                      <Checkbox
                        {...register(key)}
                        label={stepPropertyDetailsSchema.access_and_security[key].label}
                        key={key}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(false) && (
            <h3 className="process-form__title condo_form">Storage & Parking information (where applicable):</h3>
          )}
          <div className="process-form__input-block">
          {(false) && (
            <>
              <Input
                {...register('parking_spaces')}
                defaultValue=""
                label="Number of parking spaces"
                placeholder="Enter number of parking spaces"
                className="process-form__input"
              />
              <Controller
                control={control}
                name="parking_type"
                render={({ field }) => (
                  <Dropdown
                    options={stepPropertyDetailsSchema.parking_type.options}
                    label={stepPropertyDetailsSchema.parking_type.label}
                    placeholder={stepPropertyDetailsSchema.parking_type.placeholder}
                    className="process-form__input process-form__dropdown"
                    {...field}
                  />
                )}
              />
              <Input
                {...register('parking_number')}
                defaultValue=""
                label="Parking Number"
                placeholder="Enter parking number"
                className="process-form__input"
              />
              <Input
                {...register('parking_info')}
                defaultValue=""
                label="Parking Information"
                placeholder="Enter parking information"
                className="process-form__input"
              />
              <Input
                {...register('storage_number')}
                defaultValue=""
                label="Storage Number"
                placeholder="Enter storage number"
                className="process-form__input"
              />
              <Input
                {...register('storage_info')}
                defaultValue=""
                label="Storage Information"
                placeholder="Enter storage information"
                className="process-form__input"
              />
            </>
          )}
            <Input
              {...register('year_built', {
                validate: value =>
                  value ? (value > 0 && value <= currentYear) || 'Invalid value' : true
              })}
              label="Year of construction"
              placeholder="Enter year"
              type="number"
              error={errors.year_built?.message}
              className="process-form__input hide_temp"
            />
            <Controller
              control={control}
              name="utility_features"
              render={({ field }) => (
                <Dropdown
                  options={stepPropertyDetailsSchema.utility_features.options}
                  label={stepPropertyDetailsSchema.utility_features.label}
                  placeholder={stepPropertyDetailsSchema.utility_features.placeholder}
                  className="process-form__input process-form__dropdown hide_temp"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="ac_heater"
              render={({ field }) => (
                <Dropdown
                  options={stepPropertyDetailsSchema.ac_heater.options}
                  label={stepPropertyDetailsSchema.ac_heater.label}
                  placeholder={stepPropertyDetailsSchema.ac_heater.placeholder}
                  className="process-form__input process-form__dropdown hide_temp"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="flooring_type"
              render={({ field }) => (
                <Dropdown
                  options={stepPropertyDetailsSchema.flooring_type.options}
                  label={stepPropertyDetailsSchema.flooring_type.label}
                  placeholder={stepPropertyDetailsSchema.flooring_type.placeholder}
                  className="process-form__input process-form__dropdown hide_temp"
                  {...field}
                />
              )}
            />
          </div>
        </>
      )}
      <Button className="process-form__btn">{showMore ? 'Next' : 'Continue'}</Button>
    </form>
  );
};

export default StepPropertyDetails;
