import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { get, set } from 'lodash';
import styled from '@emotion/styled';
import notify from 'notify';

import { tryGetFirstError } from 'utils/requests';
import { getListingAddress } from 'utils/listing';
import { Button, SideMenu, SideMenuProps } from 'components';
import { getListing as getListingRequest } from './api';
import StepPropertyInfo from './StepPropertyInfo';
import StepPropertyDetails from './StepPropertyDetails';
import StepFinancials from './StepFinancials';
import StepReview from './StepReview';
import { StepPropertyInfoData, StepPropertyDetailsData, StepFinancialsData } from './types';
import {
  stepPropertyInfoDataKeys,
  stepPropertyDetailsDataKeys,
  stepFinancialsDataKeys
} from './data/stepDataKeys';

interface SellProcessProps extends SideMenuProps {
  listing?: Listing;
  listingId: string;
  sellProcessId?: string;
  onSuccess?: () => void;
}

const SellProcess: React.FC<SellProcessProps> = ({
  listing: listingProp,
  listingId,
  sellProcessId,
  onSuccess,
  ...props
}) => {
  const [listing, setListing] = useState<Listing | undefined>(listingProp);
  const [stepPropertyInfoData, setStepPropertyInfoData] = useState<StepPropertyInfoData>();
  const [stepPropertyDetailsData, setStepPropertyDetailsData] = useState<StepPropertyDetailsData>();
  const [stepFinancialsData, setStepFinancialsData] = useState<StepFinancialsData>();
  const [step, setStep] = useState<number>(0);
  const location = useMemo(
    () => ({
      address1: listing?.address1,
      state: listing?.state,
      city: listing?.city,
      country: listing?.country,
      postalСode: listing?.zip,
      propertyAddress: listing ? getListingAddress(listing) : ''
    }),
    [listing]
  );

  const getListing = useCallback(async listingId => {
    try {
      const listing = await getListingRequest(listingId);
      setListing(listing);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
      props.onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!listing) getListing(listingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId, listing]);

  useEffect(() => {
    const newStepPropertyInfoData: StepPropertyInfoData = {} as StepPropertyInfoData;
    const newStepPropertyDetailsData: StepPropertyDetailsData = {} as StepPropertyDetailsData;
    const newStepFinancialsData: StepFinancialsData = {} as StepFinancialsData;

    if (listing) {
      stepPropertyInfoDataKeys.forEach(key => {
        set(newStepPropertyInfoData, key, get(listing, key));
      });
      stepPropertyDetailsDataKeys.forEach(key => {
        set(newStepPropertyDetailsData, key, get(listing, key));
      });
      stepFinancialsDataKeys.forEach(key => {
        set(newStepFinancialsData, key, get(listing, key));
      });
    }

    setStepPropertyInfoData(newStepPropertyInfoData);
    setStepPropertyDetailsData(newStepPropertyDetailsData);
    setStepFinancialsData(newStepFinancialsData);
  }, [listing, sellProcessId]);

  if (!listing) return null;

  return (
    <StyledSellProcess className="sell-process" {...props}>
      <h1>
        {sellProcessId
          ? `Edit Listing Agreement for ${location?.propertyAddress}`
          : 'Create new Listing Agreement'}
      </h1>

      {listing && (
        <>
          <p className="step" onClick={() => setStep(0)} role="button" tabIndex={0}>
            1. Property Information
            {step !== 0 && stepPropertyInfoData && (
              <Button simple onClick={() => setStep(0)}>
                Edit
              </Button>
            )}
          </p>
          {step === 0 && (
            <StepPropertyInfo
              defaultValues={stepPropertyInfoData}
              onSubmit={v => {
                setStepPropertyInfoData(v);
                setStep(1);
              }}
            />
          )}
        </>
      )}

      {false && stepPropertyInfoData && (
        <>
          <p className="step" onClick={() => setStep(1)} role="button" tabIndex={0}>
            2. Property Details
            {step !== 1 && (
              <Button simple onClick={() => setStep(1)}>
                Edit
              </Button>
            )}
          </p>
          {step === 1 && (
            <StepPropertyDetails
              defaultValues={stepPropertyDetailsData}
              listingId={listingId}
              condo={
                stepPropertyInfoData?.listing_type_hr === 'Condo' &&
                stepPropertyInfoData?.condoPUD === 'yes'
              }
              townhouse={
                stepPropertyInfoData?.listing_type_hr === 'Townhouse'
              }
              multifamily={
                stepPropertyInfoData?.listing_type_hr === 'Multi-family'
              }
              onSubmit={v => {
                setStepPropertyDetailsData(v);
                setStep(2);
              }}
            />
          )}
        </>
      )}

      {stepPropertyDetailsData && (
        <>
          <p className="step" onClick={() => setStep(1)} role="button" tabIndex={0}>
            2. Financials
            {step !== 1 && (
              <Button simple onClick={() => setStep(1)}>
                Edit
              </Button>
            )}
          </p>
          {step === 1 && (
            <StepFinancials
              defaultValues={stepFinancialsData}
              onSubmit={v => {
                setStepFinancialsData(v as any);
                setStep(2);
              }}
            />
          )}
        </>
      )}

      {stepFinancialsData && (
        <>
          <p className="step" onClick={() => setStep(2)} role="button" tabIndex={0}>
            3. Review
            {step !== 2 && (
              <Button simple onClick={() => setStep(2)}>
                Edit
              </Button>
            )}
          </p>
          {step === 2 && (
            <StepReview
              listing={listing!}
              data={{
                ...stepPropertyInfoData!,
                ...stepPropertyDetailsData!,
                ...stepFinancialsData!,
                ...location,
                listingId,
                sellProcessId
              }}
              editing={Boolean(sellProcessId)}
              onEdit={setStep}
              onSuccess={onSuccess}
            />
          )}
        </>
      )}
    </StyledSellProcess>
  );
};

export default SellProcess;

const StyledSellProcess = styled(SideMenu)`
  .side-menu {
    display: flex;
    flex-direction: column;
    width: 1024px;

    & > h1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      margin-top: 0;
    }
  }

  /* styles before Container component */

  .process-container {
    max-width: 1057px;
    width: 100%;
    padding: 36px 16px 0;
    margin: auto;
  }
  .step {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    border-top: 1px solid ${props => props.theme.colors.borderColor};
    padding: 12px 0px;
    margin: 0px;
    cursor: pointer;
    &:first-of-type {
      border-top: none;
    }
    .button.simple {
      color: ${props => props.theme.colors.red};
    }
  }
  .icon-verified-house {
    path:last-of-type {
      fill: ${props => props.theme.colors.primary};
    }
  }
  .checkbox-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 0 20px;
  }
  .checkbox-wrapper {
    margin: 22px 0;
  }
  .checkbox-container {
    &__title {
      font-size: 12px;
      line-height: 20px;
      padding: 0 0 8px;
      margin: 0 0 16px;
      border-bottom: 1px solid #dadada;
    }
  }
  .checkboxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 8px 0;
  }
  .info-block {
    display: flex;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #dadada;
    border-radius: 5px;
    margin: 0 0 20px;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      margin: 0;
      &_primary {
        color: ${props => props.theme.colors.primary};
      }
    }
    &__num {
      display: flex;
      margin: 0 20px 0 0;
      font-weight: 600;
    }
    &__btn {
      min-width: 116px;
    }
    &__btn-add.simple {
      margin: 0 0 14px;
      color: ${props => props.theme.colors.primary};
    }
    &__btn-block {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 20px 0 0;
      &_add {
        margin: 0;
        display: flex;
        justify-content: flex-end;
      }
    }
    &__edit-btn {
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: ${props => props.theme.colors.primary};
      margin-left: auto;
      outline: none;

      &:focus {
        opacity: 0.7;
      }
    }
    &__align-right {
      text-align: right;
    }
  }
  .process-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 12px;
    &__title {
      font-size: 12px;
      line-height: 20px;
      margin: 16px 0 10px 0;
      font-weight: normal;
    }
    &__input-block {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(calc(50% - 20px), 1fr));
      grid-gap: 14px 20px;
      margin: 0 0 14px;
      &.seller-inputs {
        grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
      }
      .process-form__input-block {
        margin: 0;
      }
      &_three-columns {
        grid-template-columns: 2fr 3fr;
      }
      &_short {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &__input {
      width: 100%;

      input {
        height: 36px;
      }
    }
    &__radio-block {
      margin: 0 0 14px;
      & > .label {
        margin: 0 0 4px;
      }
    }
    &__btn {
      min-width: 116px;
      margin: 22px 0 0 auto;
    }
    &__textarea-block {
      margin: 14px 0;
      .textarea-input {
        min-height: 80px;
      }
    }
    &__textarea {
      width: 100%;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .checkboxes {
      &-col2 {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .info-block {
      display: flex;
      padding: 12px 20px;
      &_alert {
        .info-block__btn:first-of-type {
          margin: 0 0 12px;
        }
      }
      &__title {
        font-size: 14px;
        line-height: 24px;
      }
      &__btn-block {
        flex-direction: column;
      }
      &__btn-add {
        margin: 14px 0 20px;
      }
    }
    .process-form {
      &__input-block {
        grid-template-columns: 1fr;
        &.seller-inputs {
          grid-template-columns: inherit;
        }
      }
      &__btn {
        width: 100%;
      }
    }
    .checkbox-container {
      margin: 0 0 24px;
    }
    .checkbox-wrapper {
      margin: 0;
    }
  }
`;
