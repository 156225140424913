import dayjs from 'dayjs';
import pluralize from 'pluralize';

export const disablePastDates = input => {
  const current = dayjs().startOf('day');
  return current.isSameOrBefore(input);
};

export const disableFutureDates = input => {
  const current = dayjs();
  return current.isAfter(input);
};

export const getTimeDiffInDays = date => {
  if (!date || !dayjs(date).isValid()) return;
  return dayjs().diff(date, 'day');
};

export const getDateDifference = (
  date: number | null,
  revese = false
): { text: string; diff: number } | undefined => {
  if (!date || !dayjs(date).isValid()) return;

  let diff = getTimeDiffInDays(date);
  if (diff === undefined) return;
  if (revese) diff *= -1;
  if (diff === 0) return { text: 'Today', diff };
  return { text: `${diff} ${pluralize('day', diff)}`, diff };
};

export const formatDate = (date: number, format: string = 'MM/DD/YYYY') => {
  if (!date || !dayjs(date).isValid()) return '';

  return dayjs(date).format(format);
};
