/* eslint-disable camelcase */
import { fetchWithToken } from 'utils/fetch';
import { mapListings } from 'utils/mapping';
import { urlEncodeBody } from 'utils/requests';

export const getPeople = async (query?: {
  search?: string;
  listing_id?: string;
}): Promise<Client[]> => {
  const response = await fetchWithToken(`/people?${urlEncodeBody(query).toString()}`);
  const data = await response.json();

  return data.map(item => ({
    id: item.id,
    name: item.name,
    phone: item.phone,
    officePhone: item.business_phone,
    address: item.address_1 ? item.address_1 + (item.address_2 ? item.address_2: "") + ", " + (item.city ? item.city : "") + " " + getUSState(item.state) + " " + (item.zip ? item.zip : "") : "",
    email: item.email,
    photo: item.photo,
    roles: item.roles ? item.roles.split(',').map((item: string) => item.trim()) : [],
    position: item.roles,
    pvalue: item.pvalue,
    revenueYTD: item.ytd_revenue,
    revenueTotal: item.total_revenue,
    transactionsOpen: item.transactions_open,
    transactionsClosed: item.transactions_closed
  }));
};

export const getClientListings = async (id: number) => {
  const response = await fetchWithToken(`/people/listings/${id}`);
  const data = await response.json();

  return mapListings(data.listings);
};

export const contactClient = async values => {
  await fetchWithToken(`/people/send`, { method: 'POST', body: urlEncodeBody(values) });
};


function getUSState(stateName){
  if(stateName ==  null || stateName == ""){
    return "";
  }else{
    const states = {
      "AL": "Alabama",
      "AK": "Alaska",
      "AS": "American Samoa",
      "AZ": "Arizona",
      "AR": "Arkansas",
      "CA": "California",
      "CO": "Colorado",
      "CT": "Connecticut",
      "DE": "Delaware",
      "DC": "District Of Columbia",
      "FM": "Federated States Of Micronesia",
      "FL": "Florida",
      "GA": "Georgia",
      "GU": "Guam",
      "HI": "Hawaii",
      "ID": "Idaho",
      "IL": "Illinois",
      "IN": "Indiana",
      "IA": "Iowa",
      "KS": "Kansas",
      "KY": "Kentucky",
      "LA": "Louisiana",
      "ME": "Maine",
      "MH": "Marshall Islands",
      "MD": "Maryland",
      "MA": "Massachusetts",
      "MI": "Michigan",
      "MN": "Minnesota",
      "MS": "Mississippi",
      "MO": "Missouri",
      "MT": "Montana",
      "NE": "Nebraska",
      "NV": "Nevada",
      "NH": "New Hampshire",
      "NJ": "New Jersey",
      "NM": "New Mexico",
      "NY": "New York",
      "NC": "North Carolina",
      "ND": "North Dakota",
      "MP": "Northern Mariana Islands",
      "OH": "Ohio",
      "OK": "Oklahoma",
      "OR": "Oregon",
      "PW": "Palau",
      "PA": "Pennsylvania",
      "PR": "Puerto Rico",
      "RI": "Rhode Island",
      "SC": "South Carolina",
      "SD": "South Dakota",
      "TN": "Tennessee",
      "TX": "Texas",
      "UT": "Utah",
      "VT": "Vermont",
      "VI": "Virgin Islands",
      "VA": "Virginia",
      "WA": "Washington",
      "WV": "West Virginia",
      "WI": "Wisconsin",
      "WY": "Wyoming"
    }
    var stateAbbr =  Object.keys(states).find(key => states[key] === stateName);
    if(stateAbbr){
      return stateAbbr;
    }else{
      return stateName;
    }
  }
  
}