import React, { useState } from 'react';
import styled from '@emotion/styled';

import { formatCurrency } from 'utils/rifmFormatters';
import { Button } from '@xchange/uikit';
import ModalConfirm from 'components/ModalConfirm';
interface ExpensesProps {
  items: Expense[];
  onDelete: (value: number) => void;
}

const trashSvg = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 4.5H13" stroke="#9B9B9B" />
    <path d="M4.5 4.5V13.5H11.5V9V4" stroke="#9B9B9B" />
    <path d="M7 6V12" stroke="#9B9B9B" />
    <path d="M9 6V12" stroke="#9B9B9B" />
    <path d="M6 4V2H8H10V4" stroke="#9B9B9B" />
  </svg>

);

const Expenses: React.FC<ExpensesProps> = ({
  items = [],
  onDelete
}) => {
  const [expenseToDelete, setExpenseToDelete] = useState<number>();
  const total = items.reduce((accum, curr) => accum += curr.expense, 0);

  const handleDeleteExpense = async (expenseId: number) => {
    await onDelete(expenseId);
    setExpenseToDelete(undefined);
  };

  return (
    <StyledExpenses className="expenses">
      <div className="expenses__header expenses__item">
        <span className="expenses__item-col">Reporter</span>
        <span className="expenses__item-col">Amount</span>
        <span className="expenses__item-col">Description</span>
      </div>

      {items.map(item => (
        <div className="expenses__item" key={item.id}>
          <span className="expenses__item-col expenses__item-name">
            {item.created_by}</span>
          <span className="expenses__item-col">
            {formatCurrency(item.expense)}
          </span>
          <span className="expenses__item-col expenses__item-desc">
            {item.expense_description}
          </span>
          <span className="expenses__item-col expenses__item-delete">
            <Button simple onClick={() => setExpenseToDelete(item.id)}>
              {trashSvg}
            </Button>
          </span>
        </div>
      ))}

      <div className="expenses__footer expenses__item">
        <span className="expenses__item-col">Total:</span>
        <span className="expenses__item-col">
          {formatCurrency(total)}
        </span>
      </div>

      <ModalConfirm
        modalTitle="Delete expense"
        open={Boolean(expenseToDelete)}
        onSubmit={() => handleDeleteExpense(expenseToDelete!)}
        onClose={() => setExpenseToDelete(undefined)}>
        Are you sure you wan to delete this expense? This action can't be undone
      </ModalConfirm>
    </StyledExpenses>
  );
};

export default React.memo(Expenses);

const StyledExpenses = styled.div`
  margin: 6px 0;
  font-size: 12px;
  line-height: 16px;
  
  .expenses {
    &__item {
      display: grid;
      padding: 6px 0;
      grid-column-gap: 12px;
      grid-template-columns: 180px 1fr 1fr 1fr;
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
      &-name {
        font-weight: 500;
      }
      &-delete {
        padding-right: 16px;
        text-align: right;
      }
    }
    &__header {
      font-weight: 500;
      border-bottom: none;
    }
    &__footer {
      margin-top: 10px;
      font-weight: 700;
      border-bottom: none;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .expenses {
      &__item {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
`;