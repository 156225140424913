import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { Input, Dropdown, InputDate, Button } from '@xchange/uikit';
import { InputCurrency } from 'components';
import { REGEXP, STATES } from 'consts';

interface TransactionalDataFormProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  data?: { [key: string]: string | number; };
  onCancel: () => void;
  onSubmit: (values: { [key: string]: any; }) => void;
}

const statesOptions = Object.entries(STATES).map(([value, text]) => ({ value, text }));

const propertyTypeOptions = [
  'House',
  'Condo',
  'Townhouse',
  'Multi-family',
  'Land',
  'Other'
].map(item => ({ value: item, text: item }));

const TransactionalDataForm: React.FC<TransactionalDataFormProps> = ({
  data = {},
  onCancel,
  onSubmit
}) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      county: data.county,
      zip: data.zip,
      state: data.state,
      property_type: data.property_type,
      current_price: data.current_price,
      contract_price: data.contract_price,
      effective_date: data.effective_date,
      closing_date: data.closing_date,
      attorney_review_deadline: data.attorney_review_deadline,
      inspection_due_deadline: data.inspection_due_deadline,
      mortgage_contingency_cleartoclose_deadline: data.mortgage_contingency_cleartoclose_deadline,
      extras_total: data.extras_total,
      extras_paid: data.extras_paid,
      closing_cost_credits: data.closing_cost_credits
    }
  });

  return (
    <StyledTransactionalDataForm onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('address1', {
          required: 'Required'
        })}
        label="Address*"
        placeholder="Enter Address"
        error={errors.address1?.message}
      />
      <Input
        {...register('address2')}
        label="Address 2"
        placeholder="Enter Address 2"
      />
      <Input
        {...register('city', {
          required: 'Required'
        })}
        label="City*"
        placeholder="Enter City"
        error={errors.city?.message}
      />
      <Input
        {...register('county')}
        label="County"
        placeholder="Enter County"
      />
      <Controller
        control={control}
        rules={{ required: 'Required' }}
        name="state"
        defaultValue=""
        render={({ field, formState: { errors } }) => (
          <Dropdown
            options={statesOptions}
            label="State*"
            placeholder="Choose State"
            error={errors.state?.message}
            {...field}
          />
        )}
      />
      <Input
        {...register('zip', {
          required: 'Required',
          pattern: { value: REGEXP.ZIP_CODE, message: 'Invalid Zip Code' },
          maxLength: { value: 5, message: 'Invalid Zip Code' }
        })}
        label="Zip Code*"
        placeholder="Enter Zip Code"
        error={errors.zip?.message}
      />

      <hr />

      <Controller
        control={control}
        name="property_type"
        defaultValue=""
        render={({ field }) => (
          <Dropdown
            options={propertyTypeOptions}
            error={errors.property_type?.message}
            label="Project Type"
            placeholder="Select Project Type"
            {...field}
          />
        )}
      />

      <hr />

      <Controller
        control={control}
        name="current_price"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Listing Price"
            placeholder="Enter Price"
            error={errors.current_price?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="contract_price"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Contract Purchase Price"
            placeholder="Enter Price"
            error={errors.contract_price?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="effective_date"
        defaultValue=""
        render={({ field }) => (
          <InputDate
            inputProps={{
              label: 'Effective Date',
              placeholder: 'Select Date',
              error: errors.effective_date?.message
            }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="attorney_review_deadline"
        defaultValue=""
        render={({ field }) => (
          <InputDate
            inputProps={{
              label: 'Attorney Review Deadline',
              placeholder: 'Select Date',
              error: errors.attorney_review_deadline?.message
            }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="inspection_due_deadline"
        defaultValue=""
        render={({ field }) => (
          <InputDate
            inputProps={{
              label: 'Inspection Deadline',
              placeholder: 'Select Date',
              error: errors.inspection_due_deadline?.message
            }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="mortgage_contingency_cleartoclose_deadline"
        defaultValue=""
        render={({ field }) => (
          <InputDate
            inputProps={{
              label: 'Mortgage Contingency Deadline',
              placeholder: 'Select Date',
              error: errors.mortgage_contingency_cleartoclose_deadline?.message
            }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="closing_date"
        defaultValue=""
        render={({ field }) => (
          <InputDate
            inputProps={{
              label: 'Contract Closing Date',
              placeholder: 'Select Date',
              error: errors.closing_date?.message
            }}
            {...field}
          />
        )}
      />

      <hr />

      <Controller
        control={control}
        name="extras_total"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Total Extras"
            placeholder="Enter Price"
            error={errors.extras_total?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="extras_paid"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Total Extras Paid"
            placeholder="Enter Price"
            error={errors.extras_paid?.message}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="closing_cost_credits"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Closing Cost Credit"
            placeholder="Enter Price"
            error={errors.closing_cost_credits?.message}
            {...field}
          />
        )}
      />

      <div className="button-group">
        <Button secondary onClick={onCancel}>Cancel</Button>
        <Button>Update</Button>
      </div>
    </StyledTransactionalDataForm>
  );
};
export default TransactionalDataForm;

const StyledTransactionalDataForm = styled.form`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 12px;
  .button-group {
    margin-top: 24px;
    grid-column: span 2;
    .button + .button {
      margin-left: 12px;
    }
  }
  .input {
    display: block;
  }
  .dropdown, .input-datepicker {
    width: 100%;
  }
  label {
    display: block;
    margin-bottom: 4px;
  }
  hr {
    margin: 16px 0 8px;
    grid-column: span 2;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    .input, .dropdown, .input-datepicker {
      margin-bottom: 16px;
    }
  }
`;
