import React, { useMemo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Input from 'components/Input';
import { InputDate } from '@xchange/uikit';
import { CalendarItem } from 'views/CalendarPage/calendar.d';
import {
  getTimeOptions,
  getDefaultEndTime
} from 'views/CalendarPage/components/EditEventModal/helpers';
import useAuthContext from 'hooks/useAuthContext';

interface EditCustomEventFormProps {
  isClosingEvent?: boolean;
  initialData?: Partial<CalendarItem>;
  usersOptions?: DropdownOption[];
  onSubmitNew: (values) => void;
  onSubmitEdit: (values) => void;
  hidePersonalEvent?: boolean;
}

const EditCustomEventForm: React.FC<EditCustomEventFormProps> = ({
  initialData,
  usersOptions,
  isClosingEvent,
  onSubmitNew,
  onSubmitEdit,
  hidePersonalEvent = false
}) => {
  const restrictEdit = Boolean(initialData?.id) && !initialData?.editable;
  const { profile } = useAuthContext();
  const start = initialData?.start ? dayjs(initialData.start).format('hh:mm A') : undefined;
  const end = initialData?.end ? dayjs(initialData.end).format('hh:mm A') : undefined;
  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      ...initialData,
      dateType: 'event',
      date: initialData?.start?.format('MM/DD/YYYY'),
      start,
      end
    }
  });
  const { startOptions, endOptions } = useMemo(() => getTimeOptions(initialData?.start), [
    initialData
  ]);
  const isEditing = Boolean(initialData?.id);
  const currentUser = usersOptions?.find(user => user.search === profile?.email);
  const personalEvent = watch('personalEvent', false);

  const submit = form => {
    if (isEditing) onSubmitEdit({ ...form, id: initialData?.id });
    else onSubmitNew(form);
  };

  useEffect(() => {
    if (personalEvent && currentUser) setValue('assigneeId', currentUser?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalEvent]);

  return (
    <StyledForm onSubmit={handleSubmit(submit)}>
      {!hidePersonalEvent && (
        <Checkbox
          {...register('personalEvent')}
          label="Personal event"
          disabled={restrictEdit || initialData?.personalEvent}
          data-cy="checkbox_personal_event"
        />
      )}

      <Input
        {...register('title', { required: 'Required' })}
        error={errors.title?.message}
        label="Title"
        placeholder="Enter"
        disabled={restrictEdit || isClosingEvent}
        data-cy="input_title"
      />
      <Controller
        control={control}
        rules={{
          required: 'Required',
          pattern: {
            value: /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/,
            message: 'Invalid date'
          }
        }}
        name="date"
        defaultValue={dayjs().format('MM/DD/YYYY')}
        render={({ field, formState: { errors } }) => (
          <InputDate
            inputProps={{
              label: 'Date',
              error: errors.date?.message,
              disabled: restrictEdit,
              'data-cy': 'input_date'
            }}
            {...field}
          />
        )}
      />
      <div className="time-options">
        <Controller
          control={control}
          rules={{ required: 'Required' }}
          name="start"
          defaultValue=""
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              options={startOptions}
              label="Start"
              placeholder="Select"
              disabled={restrictEdit}
              error={errors.start?.message}
              onChange={value => {
                onChange(value);
                setValue('end', getDefaultEndTime(value));
              }}
              data-cy="dropdown_start"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          rules={{ required: 'Required' }}
          name="end"
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <Dropdown
              options={endOptions}
              label="End"
              placeholder="Select"
              disabled={restrictEdit}
              error={errors.end?.message}
              data-cy="dropdown_end"
              {...field}
            />
          )}
        />
      </div>

      {initialData?.propertyName && (
        <Input {...register('propertyName')} label="Project" disabled data-cy="input_project" />
      )}

      <Controller
        control={control}
        name="assigneeId"
        render={({ field, formState: { errors } }) => (
          <Dropdown
            search
            options={usersOptions}
            label={
              <>
                Assigned to <span className="optional">(optional)</span>
              </>
            }
            placeholder="Select"
            disabled={restrictEdit || personalEvent || initialData?.personalEvent}
            error={errors.assigneeId?.message}
            data-cy="dropdown_assignee"
            {...field}
          />
        )}
      />
      <Button type="submit" data-cy="button_submit">
        {isEditing ? 'Edit' : 'Add new'}
      </Button>
    </StyledForm>
  );
};

export default EditCustomEventForm;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 16px;
  }

  & > .button {
    height: 40px;
  }

  .time-options {
    display: flex;

    .dropdown:first-of-type {
      margin-right: 16px;
    }
  }

  .optional {
    color: ${props => props.theme.colors.grayDark};
  }
`;
