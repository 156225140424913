import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { DropdownOption } from 'components/Dropdown';
import { BUSINESS_HOURS_END, BUSINESS_HOURS_START } from 'views/CalendarPage/consts';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

export const getTimeOptions = startDate => {
  let startOfDay = dayjs(startDate)
    .hour(BUSINESS_HOURS_START)
    .minute(0)
    .second(0);
  const endOfDay = dayjs(startDate)
    .hour(BUSINESS_HOURS_END)
    .minute(0)
    .second(0);

  const startOptions: DropdownOption[] = [];
  const endOptions: DropdownOption[] = [];

  while (dayjs(startOfDay).isSameOrBefore(endOfDay.subtract(30, 'minute'))) {
    const currentTime = {
      value: startOfDay.format('hh:mm A'),
      text: startOfDay.format('hh:mm A')
    };
    const defaultEndTime = startOfDay.add(30, 'minute');
    startOptions.push(currentTime);
    endOptions.push({
      value: defaultEndTime.format('hh:mm A'),
      text: defaultEndTime.format('hh:mm A')
    });
    startOfDay = startOfDay.add(30, 'minute');
  }

  return {
    startOptions,
    endOptions
  };
};

export const minTime = dayjs()
  .hour(BUSINESS_HOURS_START)
  .minute(0)
  .second(0);
export const maxTime = dayjs()
  .hour(BUSINESS_HOURS_END)
  .minute(0)
  .second(0);

export const getDefaultEndTime = (start?: string) => {
  let endTime = start
    ? dayjs(start, 'hh:mm A')
        .clone()
        .add(2, 'hour')
    : undefined;

  if (endTime?.isValid() && endTime.isAfter(maxTime)) {
    endTime = maxTime;
  }

  return endTime?.format('hh:mm A');
};
