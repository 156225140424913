import { fetchWithToken } from 'utils/fetch';
import { urlEncodeBody, checkSuccess } from 'utils/requests';

export async function getListing(id: string): Promise<Listing> {
  const response = await fetchWithToken(`/listings/listing/${id}`);
  const data = await response.json();

  checkSuccess(data);

  return data;
}

export async function submitSellProcessForm(values: { [key: string]: any }): Promise<number> {
  const { listingId, ...rest } = values;
  const data = {
    data: JSON.stringify(rest),
    listing_id: listingId
  };
  const response = await fetchWithToken('/sell', {
    method: 'PUT',
    body: urlEncodeBody(data)
  });
  const jsonData = await response.json();

  checkSuccess(jsonData);

  return jsonData.document_id;
}

export async function getSellprocessData(sellprocessId: string): Promise<any> {
  const response = await fetchWithToken(`/sell/${sellprocessId}`);
  const data = await response.json();
  // console.log('getSellprocessData data', sellprocessId, data);
  return data;
}
