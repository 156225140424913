import React from 'react';
import styled from '@emotion/styled';
import notify from 'notify';

import { sendSignatureReminder } from 'api/documents';

interface SignatureListProps extends React.HTMLAttributes<HTMLDivElement> {
  items?: [];
  documentId?: number,
  onEmpty?: () => void;
}

const SignatureList: React.FC<SignatureListProps> = ({
  items,
  documentId,
  onEmpty
}) => {

    const sendSignatureReminderFunction = (memberId,documentId) => {
        sendSignatureReminder(memberId,documentId);
        onEmpty();
        notify('Signature reminder sent.');
    }

    return (
        <StyledSignatureList>
            <table>
                
                {items?.map((item,key) => (  
                    <tr key={key}>
                        <td>{item.user_name}</td> <td className="signature-reminder-role"> {item.role.match(/[A-Z][a-z]+/g).join(" ")} </td> <td>{item.signed ? <span className="ttSigned">Signed!</span> : <span onClick={e => sendSignatureReminderFunction(item.id,documentId)} className="ttNotSigned">Send signature reminder</span>}</td>
                    </tr>
                ))}
            </table>
            
        </StyledSignatureList>
    );
};

export default SignatureList;

const StyledSignatureList = styled.div`
    .signature-reminder-role{font-weight: bold;}
    .ttNotSigned{color: #df1721;cursor:pointer;}
    .ttSigned{color: #62B120;}
`

