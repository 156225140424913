import { fetchWithToken } from 'utils/fetch';

export const getRoles = async (): Promise<Array<Role>> => {
  const response = await fetchWithToken('/people/roles');
  const data = await response.json();

  return data;
};

export const getRolesByListingId = async (listingId: string): Promise<Array<Role>> => {
  const response = await fetchWithToken(`/listings/${listingId}/roles`);
  const data = await response.json();

  return data;
};
