import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Dropdown,
  DropdownOption,
  Icon,
  Input,
  InputCurrency,
  InputAutocomplete
} from 'components';
import { useForm, Controller } from 'react-hook-form';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { getTransactionServices, createTransaction } from 'api/billing';
import { getPeople } from 'api/people';
import { AutocompleteOption } from 'components/InputAutocomplete';
import { getListingsAddresses } from 'api/listings';
import { Transaction } from 'views/TransactionsPage/transactions.d';

interface RequestPaymentFormProps extends React.HTMLAttributes<HTMLDivElement> {
  listing?: Listing;
  onReturn: () => void;
  onSuccess?: (transaction: Transaction) => void;
}

const RequestPaymentForm: React.FC<RequestPaymentFormProps> = ({
  listing,
  onReturn,
  onSuccess,
  ...props
}) => {
  const { register, control, watch, handleSubmit } = useForm();
  const [servicesOptions, setServicesOptions] = useState<DropdownOption[]>();
  const [clients, setClients] = useState<Client[]>();
  const [listingInfo, setListingInfo] = useState<{ id: string; address: string; } | undefined>(
    listing
      ? { id: listing.id, address: listing.address1 || listing.googleAddress || '' }
      : undefined
  );
  const service = watch('service');

  const fetchTransactionServicesOptions = useCallback(async () => {
    try {
      const services = await getTransactionServices();
      const servicesOptions: DropdownOption[] = services.map(item => ({
        value: item.name,
        text: item.name
      }));
      setServicesOptions(servicesOptions);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, []);

  const fetchListingsOptions = useCallback(async (search?: string) => {
    try {
      const listingsAddresses = await getListingsAddresses({ search });
      const options: AutocompleteOption[] = listingsAddresses.map(item => ({
        id: item.id,
        text: item.propertyAddress
      }));
      return options;
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, []);

  const fetchPayerOptions = useCallback(async () => {
    try {
      const people = await getPeople({ listing_id: listing?.id });
      if (listing && people.length === 0) throw Error('Listing members not found');
      setClients(people);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTransactionServicesOptions();
    fetchPayerOptions();
    // transactions page workflow
    if (!listing) fetchListingsOptions();
  }, [fetchListingsOptions, fetchTransactionServicesOptions, fetchPayerOptions, listing]);

  const submit = async formValues => {
    try {
      if (!listingInfo) throw Error('Select existing listing');
      const user = clients?.find(item => item.id === formValues.payer);
      if (!user) throw Error('User not found');

      const transaction = await createTransaction({
        listingId: listingInfo.id,
        description: listingInfo.address,
        service: formValues.serviceCustom || formValues.service,
        amount: formValues.amount,
        name: user.name!,
        email: user.email,
        phone: user.phone
      });

      notify('Request payment success.');
      onSuccess?.(transaction);
      onReturn();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const payerOptions = useMemo(
    () =>
      clients?.map(item => ({
        value: item.id,
        text: item.name ? `${item.name} (${item.email})` : item.email
      })),
    [clients]
  );

  return (
    <StyledRequestPaymentForm {...props}>
      <div className="header-section">
        <Button className="return" secondary onClick={onReturn}>
          <Icon name="arrow-simple" />
        </Button>
        <h3 className="title">Request Payment</h3>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form-grid">
          <Controller
            control={control}
            name="service"
            rules={{ required: 'Required' }}
            defaultValue={null}
            render={({ field, formState: { errors } }) => (
              <Dropdown
                className="two-wide"
                label="Service"
                placeholder="Select"
                error={errors.service?.message}
                options={servicesOptions}
                {...field}
              />
            )}
          />
          {service === 'Other' && (
            <Input
              className="two-wide"
              label="Other service"
              placeholder="Enter"
              {...register('serviceCustom', { required: 'Required' })}
            />
          )}
          {!listing && (
            <Controller
              control={control}
              name="listing"
              rules={{ required: 'Required' }}
              defaultValue={null}
              render={({ field, formState: { errors } }) => (
                <InputAutocomplete
                  error={errors.payer?.message}
                  label="Listing"
                  placeholder="Select"
                  minLength={1}
                  getOptions={fetchListingsOptions}
                  onOptionSelect={option =>
                    setListingInfo({ id: option.id as string, address: option.text })
                  }
                  {...field}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="payer"
            rules={{ required: 'Required' }}
            defaultValue={null}
            render={({ field, formState: { errors } }) => (
              <Dropdown
                label="Payer"
                placeholder="Select"
                error={errors.payer?.message}
                options={payerOptions}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            rules={{ required: 'Required' }}
            name="amount"
            defaultValue=""
            render={({ field, formState: { errors } }) => (
              <InputCurrency
                label="Amount"
                placeholder="Enter"
                error={errors.amount?.message}
                {...field}
              />
            )}
          />
        </div>
        <div className="form-controls">
          <Button secondary type="button" onClick={onReturn}>
            Cancel
          </Button>
          <Button type="submit">Request</Button>
        </div>
      </form>
    </StyledRequestPaymentForm>
  );
};

export default RequestPaymentForm;

const StyledRequestPaymentForm = styled.div`
  .header-section {
    .return {
      float: left;
      margin-right: 16px;
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
    }
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 20px;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      grid-template-columns: 1fr;

      .two-wide {
        grid-column: 1 !important;
      }
    }

    .input,
    .dropdown {
      width: 100%;
    }

    .two-wide {
      grid-column: span 2;
    }
  }

  .form-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button {
      margin-left: 16px;
    }
  }
`;
