import React from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { Button, Icon } from 'components';

interface WorkspaceNoteProps {
  data: WorkspaceNote;
  disableDelete: boolean;
  onDelete: (noteId: number) => void;
}

const WorkspaceNote: React.FC<WorkspaceNoteProps> = ({ data, onDelete, disableDelete }) => {
  return (
    <StyledWorkspaceNote>
      <div className="note-text">
        {data.text}
        <Button className="delete-button" simple onClick={() => onDelete(data.id)} disabled={disableDelete}>
          <Icon name="trash" />
        </Button>
      </div>
      <div className="note-info">
        {dayjs(data.createdOn).format('MM/DD/YYYY')} · {data.name}
      </div>
    </StyledWorkspaceNote>
  );
};

export default WorkspaceNote;

const StyledWorkspaceNote = styled.div`
  border: 1px solid ${props => props.theme.colors.borderColor};
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 8px;
  background-color: #F1F1F1;

  .delete-button {
    float: right;
    fill: ${props => props.theme.colors.grayDark};
    background-color: #F1F1F1;
  }

  .note-text {
    padding: 4px 8px 4px 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    white-space: pre-line;
    overflow: hidden;
    border-bottom: 1px solid #F1F1F1;
    
  }

  .note-info {
    color: ${props => props.theme.colors.grayDark};
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px 4px 8px;
    background-color: #FFFFFF;
  }
`;
