import styled from '@emotion/styled';

const BuyProcessHint = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dadada;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding: 10px 16px;
  margin-bottom: 20px;

  svg {
    vertical-align: text-bottom;
    margin-right: 12px;
  }
`;

export default BuyProcessHint;
