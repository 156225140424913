import React, {useMemo} from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { Input, Checkbox } from '@xchange/uikit';
import { InputCurrency, InputPercentage } from 'components';
import { REGEXP } from 'consts';
interface NewDocumentFormProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  data?: { [key: string]: any; };
  listing: Listing | undefined;
  template?: string;
  templates?: FormTemplate[];
  onSubmit: (values: { [key: string]: any; }) => void;
}

const NewDocumentForm: React.FC<NewDocumentFormProps> = ({ data = {}, template, listing, templates, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    handleSubmit
  } = useForm({
    defaultValues: data
  });
  const brokerFilling = watch('broker_filling');

  const templateButtons = useMemo(() => {
    return templates.filter(item => item.displayName.includes('Disclosure')).map(item => (
      <Checkbox {...register(item.name)} className="needed_templates" key={item.displayName} label={item.displayName} />
    ));
  }, [templates]);

  const getForm = () => {
    if (template?.includes('Disclosure')) {
      return (
        <>
          <label className="custom-label">Which Disclosures would you like to generate?</label>
          {templateButtons}
      
          <Input
            {...register('email', {
              required: 'Required',
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email address'
              }
            })}
            error={errors.email?.message}
            label="Email Address*"
            placeholder="Enter Email"
            disabled={brokerFilling}
          />
          <Checkbox {...register('broker_filling')} label="I want to fill in the disclosure myself" />
          
        </>
      );
    }

    switch (template) {
      case 'Expense':
        return (
          <>
            <Controller
              control={control}
              name="expenseSum"
              defaultValue={0}
              render={({ field, formState: { errors } }) => (
                <InputCurrency
                  label="Expense Sum"
                  placeholder="Enter"
                  error={errors.expenseSum?.message}
                  {...field}
                />
              )}
            />
            <Input {...register('comment')} label="Comment" placeholder="Enter comment" />
          </>
        );
      case 'Commission Statement':
        return (
          <>
            <Controller
              control={control}
              name="marketing_cost"
              defaultValue={listing.marketingCost}
              render={({ field, formState: { errors } }) => (
                <InputCurrency
                  label="Upfront Flat Fee ($)"
                  placeholder="Enter"
                  error={errors.marketing_cost?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                min: {
                  value: 0,
                  message: 'Value cannot be less than 0'
                },
                max: {
                  value: 100,
                  message: 'Value cannot be greater than 100'
                }
              }}
              name="sale_commission"
              defaultValue={listing.saleCommission}
              render={({ field, formState: { errors } }) => (
                <InputPercentage
                  label="Total Broker Commissions (%)"
                  placeholder="Enter"
                  error={errors.sale_commission?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                min: {
                  value: 0,
                  message: 'Value cannot be less than 0'
                },
                max: {
                  value: 100,
                  message: 'Value cannot be greater than 100'
                }
              }}
              name="coop_commission"
              defaultValue={listing.coopCommission}
              render={({ field, formState: { errors } }) => (
                <InputPercentage
                  label="Coop Commissions (%)"
                  placeholder="Enter"
                  error={errors.coop_commission?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="additional_sale_commission"
              defaultValue={listing.additionalSaleCommission}
              render={({ field, formState: { errors } }) => (
                <InputCurrency
                  label="Additional Commissions ($)"
                  placeholder="Enter"
                  error={errors.additional_sale_commission?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="reimbursement"
              defaultValue={listing.reimbursement}
              render={({ field, formState: { errors } }) => (
                <InputCurrency
                  label="Early Termination Fee ($)"
                  placeholder="Enter"
                  error={errors.reimbursement?.message}
                  {...field}
                />
              )}
            />
            
            <Controller
              control={control}
              name="mls_fee"
              defaultValue={listing.mlsFee}
              render={({ field, formState: { errors } }) => (
                <InputCurrency
                  label="MLS Fee ($)"
                  placeholder="Enter"
                  error={errors.mls_fee?.message}
                  {...field}
                />
              )}
            />
          </>
        );
      case 'Closing Statement':
        return (
          <>
            <Controller
              control={control}
              name="countyTaxes"
              defaultValue={0}
              render={({ field, formState: { errors } }) => (
                <InputCurrency
                  label="County Taxes"
                  placeholder="Enter"
                  error={errors.countyTaxes?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="processingFees"
              defaultValue={0}
              render={({ field, formState: { errors } }) => (
                <InputCurrency
                  label="Processing Fees"
                  placeholder="Enter"
                  error={errors.processingFees?.message}
                  {...field}
                />
              )}
            />
            <Input
              {...register('endorsements')}
              error={errors.endorsements?.message}
              label="Endorsements"
              placeholder="Enter"
            />
          </>
        );
      default:
        return null;
    }
  };

  if (!template) return null;

  return (
    <StyledNewDocumentForm id="newdocform" onSubmit={handleSubmit(onSubmit)}>
      {getForm()}
    </StyledNewDocumentForm>
  );
};
export default NewDocumentForm;

const StyledNewDocumentForm = styled.form`
  margin-bottom: auto;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 16px;
  row-gap: 16px;
  .input {
    display: block;
  }
  .checkbox {
    grid-column: span 2;
  }

  .custom-label{     color: black;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;}

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    .input {
      margin-bottom: 16px;
    }
  }
`;
