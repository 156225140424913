import React from 'react';

import { StepPropertyInfoData } from './types';

interface PropertyInfoSummaryProps {
  data: StepPropertyInfoData;
  onEdit: (value: number) => void;
}

const PropertyInfoSummary: React.FC<PropertyInfoSummaryProps> = ({ data, onEdit }) => {
  const isCondo = data.listing_type_hr === 'Condo';

  return (
    <div className="review-wrapper">
      <div className="review-header">
        <h3 className="review-title">Property Information:</h3>
        <button className="info-block__edit-btn" onClick={() => onEdit(0)}>
          Edit
        </button>
      </div>
      <div className="review-block">
        <div className="review-info-block">
          <p className="review-text">
            <strong>My Property is for:</strong> {data.saleOrRent}
          </p>
          <p className="review-text">
            <strong>Type:</strong> {data.listing_type_hr}
          </p>
          {/*<p className="review-text">
            <strong>Bedrooms:</strong> {data.beds}
          </p>
          <p className="review-text">
            <strong>Bathrooms:</strong> {data.baths}
          </p>*/}
          {isCondo && (
            <p className="review-text">
              <strong>Condo PUD:</strong> {data.condoPUD}
            </p>
          )}
          <p className="review-text">
            <strong>Living area:</strong> {data.sqft+" sq ft" || 'N/A'}
          </p>
          {!isCondo && (
            <p className="review-text">
              <strong>Lot Size:</strong> {data.lotSize || 'N/A'}
            </p>
          )}
          {data.condoPUD && (
            <>
              <p className="review-text">
                <strong>Parking Number:</strong> {data.condo_parking_number || 'N/A'}
              </p>
              <p className="review-text">
                <strong>Parking Information:</strong> {data.condo_parking_info || 'N/A'}
              </p>
              <p className="review-text">
                <strong>Storage Number:</strong> {data.condo_storage_number || 'N/A'}
              </p>
              <p className="review-text">
                <strong>Storage Information:</strong> {data.condo_storage_info || 'N/A'}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PropertyInfoSummary);
