/* eslint-disable camelcase */
import { snakeCase } from 'lodash';
import dayjs from 'dayjs';
import { DropdownOption, AutocompleteOption } from 'components';
import { fetchWithToken } from 'utils/fetch';
import { checkSuccess, urlEncodeBody } from 'utils/requests';
import keysToCamel from 'utils/keysToCamel';
import {
  mapWorkspaceNotes,
  mapWorkspaceActivityLog,
  mapWorkspaceOffer,
  mapListings
} from 'utils/mapping';
import { CalendarItem } from 'views/CalendarPage/calendar.d';
import { mapCalendarData, mapDocuments } from 'views/CalendarPage/mapping';

export async function getOffers(listingId: string): Promise<WorkspaceOffer[]> {
  const response = await fetchWithToken(`/listings/offers/${listingId}`, {
    method: 'GET'
  });
  const data = await response.json();
  const offers = data.offers.map(mapWorkspaceOffer);

  return offers;
}

export async function changeOfferStatus(payload?: WorkspaceOffer): Promise<void> {
  const formData = urlEncodeBody({
    buy_process_id: payload?.buyProcessId,
    status: payload?.status
  });
  await fetchWithToken(`/listings/offers`, {
    method: 'POST',
    body: formData
  });
}

export async function getMembers(listingId: string): Promise<WorkspaceMember[]> {
  const response = await fetchWithToken(`/people/listing/${listingId}`, {
    method: 'GET'
  });
  const data = await response.json();

  return keysToCamel(data);
}

export async function updateParticipant(values): Promise<void> {
  const body = Object.keys(values).reduce((accum, current) => {
    // eslint-disable-next-line no-param-reassign
    accum[snakeCase(current)] = values[current];
    return accum;
  }, {});

  await fetchWithToken(`/people`, {
    method: 'PUT',
    body: urlEncodeBody(body)
  });
}

export async function removeParticipant(listingId: string, memberId) {
  await fetchWithToken(`/people/listing/${listingId}`, {
    method: 'DELETE',
    body: urlEncodeBody({ people_ids: memberId })
  });
}

export async function getEmailTemplateDataRequest(listingId: string, emailTemplateId: number){
  const response = await fetchWithToken(`/api/v1/workspace/tasks/email_templates/${listingId}/${emailTemplateId}`);

  const data = await response.json();

  return {
      templateData: data
  }
}

export async function getWorksapceCalendarItems(
  listingId: string
): Promise<{ tasks: CalendarItem[]; deadlines: WorkspaceDeadline[]; documents: DropdownOption[] }> {
  const response = await fetchWithToken(`/calendar/listing/${listingId}`);

  const data = await response.json();

  return {
    tasks: mapCalendarData(data.tasks),
    deadlines: data.deadlines.map(item => ({
      id: item.id,
      name: item.name,
      date: item.deadline * 1000,
      xp: false
    })),
    documents: mapDocuments(data.docTypes)
  };
}

export async function getWorksapceDeadlinesXP(listingId: string): Promise<WorkspaceDeadline[]> {
  const response = await fetchWithToken(`/api/v1/workspace/deadlines/xp/${listingId}`);

  const data = await response.json();

  return data.map(item => ({ id: item.id, name: item.name, date: item.date * 1000, xp: true }));
}

export async function getNotes(listingId: string): Promise<WorkspaceNote[]> {
  const response = await fetchWithToken(`/notes/${listingId}`);

  const data = await response.json();

  return mapWorkspaceNotes(data);
}

export async function addNote(payload: {
  listingId: string;
  text: string;
}): Promise<WorkspaceNote[]> {
  const response = await fetchWithToken(`/notes`, {
    method: 'PUT',
    body: urlEncodeBody({ note: payload.text, listing_id: payload.listingId })
  });

  const data = await response.json();

  return mapWorkspaceNotes(data);
}

export async function deleteNote(noteId: number): Promise<void> {
  const response = await fetchWithToken(`/notes/${noteId}`, { method: 'DELETE' });

  await response.json();
}

export async function getActivityLog(listingId: string): Promise<WorkspaceActivityLogItem[]> {
  const response = await fetchWithToken(`/activity_log/${listingId}`);

  const data = await response.json();

  return mapWorkspaceActivityLog(data);
}

export async function getAutocompleteEmails(params: {
  search?: string;
}): Promise<AutocompleteOption[]> {
  const response = await fetchWithToken(`/people/emails?${urlEncodeBody(params).toString()}`);
  const data = await response.json();
  return data.map(item => ({ id: item.id, text: item.email }));
}

export async function getPeopleById(id: number): Promise<WorkspaceMember> {
  const response = await fetchWithToken(`/people/listings/${id}`);
  const data = await response.json();
  return keysToCamel(data.people);
}

export async function addOrder(values: {
  task_type: string;
  vendor: string;
  vendor_email: string;
  vendor_name: string;
  deadline: string;
  offered_price: string;
  note: string;
}): Promise<void> {
  const response = await fetchWithToken(`/vendors`, {
    method: 'POST',
    body: urlEncodeBody(values)
  });
  await response.json();
  // return keysToCamel(data.people);
}

export async function getVendors(type?: string) {
  let url = '/vendors';
  if (type) url += `?type=${type}`;
  const response = await fetchWithToken(url);
  const data = await response.json();
  return keysToCamel(data);
}

export async function deleteListing(listingId: string) {
  await fetchWithToken(`/listings/${listingId}`, {
    method: 'DELETE'
  });
}

export async function remindMemberToSign(memberId: number, documentId) {
  await fetchWithToken(`/documents/pdf_editor/${documentId}/signature_reminder`, {
    method: 'POST',
    body: urlEncodeBody({ member_id: memberId })
  });
}

export async function addListingMlsId(listingId: string, mlsListingId: string): Promise<Listing> {
  const response = await fetchWithToken(`/listings/${listingId}/merge`, {
    method: 'POST',
    body: urlEncodeBody({ mls_listing_id: mlsListingId })
  });
  const data = await response.json();

  checkSuccess(data);

  return mapListings([data])?.[0];
}

export async function getTransactionalData(
  listingId: string
): Promise<{
  data: { [key: string]: string | number };
  expenses: any[];
}> {
  const response = await fetchWithToken(`api/v1/workspace/transactional_data/listing/${listingId}`);
  const data = await response.json();

  checkSuccess(data);

  const {
    attorney_review_deadline,
    closing_date,
    effective_date,
    inspection_due_deadline,
    mortgage_contingency_cleartoclose_deadline,
    ...rest
  } = data.transactional_data;

  return {
    expenses: Object.values(data.expenses),
    data: {
      attorney_review_deadline: attorney_review_deadline * 1000,
      closing_date: closing_date * 1000,
      effective_date: effective_date * 1000,
      inspection_due_deadline: inspection_due_deadline * 1000,
      mortgage_contingency_cleartoclose_deadline: mortgage_contingency_cleartoclose_deadline * 1000,
      ...rest
    }
  };
}

export async function updateTransactionalData(
  payload: { [key: string]: string | number },
  listingId: string
) {
  const {
    earnest_money_1_deposited,
    earnest_money_2_deposited,
    earnest_money_3_deposited,
    earnest_money_4_deposited,
    effective_date,
    closing_date,
    attorney_review_deadline,
    inspection_due_deadline,
    mortgage_contingency_cleartoclose_deadline,
    ...rest
  } = payload;
  const formData = urlEncodeBody({
    earnest_money_1_deposited: Number(earnest_money_1_deposited),
    earnest_money_2_deposited: Number(earnest_money_2_deposited),
    earnest_money_3_deposited: Number(earnest_money_3_deposited),
    earnest_money_4_deposited: Number(earnest_money_4_deposited),
    effective_date: effective_date ? dayjs(effective_date).unix() : undefined,
    closing_date: closing_date ? dayjs(closing_date).unix() : undefined,
    attorney_review_deadline: attorney_review_deadline
      ? dayjs(attorney_review_deadline).unix()
      : undefined,
    inspection_due_deadline: inspection_due_deadline
      ? dayjs(inspection_due_deadline).unix()
      : undefined,
    mortgage_contingency_cleartoclose_deadline: mortgage_contingency_cleartoclose_deadline
      ? dayjs(mortgage_contingency_cleartoclose_deadline).unix()
      : undefined,
    ...rest
  });
  await fetchWithToken(`api/v1/workspace/transactional_data/listing/${listingId}`, {
    method: 'POST',
    body: formData
  });
}

export async function addExpense(
  payload: {
    expense: number;
    expense_description: string;
  },
  listingId: string
) {
  const formData = urlEncodeBody(payload);

  await fetchWithToken(`api/v1/workspace/expenses/listing/${listingId}`, {
    method: 'POST',
    body: formData
  });
}

export async function deleteExpense(expenseId: number) {
  await fetchWithToken(`api/v1/workspace/expenses/listing/${expenseId}`, {
    method: 'DELETE'
  });
}
