import React from 'react';

import styled from '@emotion/styled';
import { PopMenu, PopMenuProps/*, Button, Icon*/ } from '@xchange/uikit';

interface SignatureReminderTooltipProps extends PopMenuProps {
  tooltipTitle?: React.ReactNode;
  onClose?: (event?: any) => void;
}

const modifiers = [
  { name: 'flip', options: { fallbackPlacements: ['top-start', 'right'] } },
  { name: 'preventOverflow', options: { padding: 16 } },
  { name: 'offset', options: { offset: [0, 15] } }
];

const SignatureReminderTooltip: React.FC<SignatureReminderTooltipProps> = ({
  className,
  children,
  tooltipTitle,
  placement = 'bottom-start',
  onClose,
  ...props
}) => {
  return (
    <StyledSignatureReminderTooltip
      placement={placement}
      modifiers={modifiers}
      onOutsideClick={onClose}
      unmountChildren
      {...props}
    >
      {/* <Button onClick={onClose} className="button-close" data-cy="button_close_tooltip">
        <Icon name="mini-x" />
      </Button> */}
      <h3 className="title">{tooltipTitle}</h3>
      <div className="menu-content scrollable">{children}</div>
    </StyledSignatureReminderTooltip>
  );
};

export default SignatureReminderTooltip;

const StyledSignatureReminderTooltip = styled(PopMenu)`
  width: auto;
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: ${props => props.theme.misc.borderRadius};

  .title {
    max-width: calc(100% - 24px);
    padding-right: 12px;
    display: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .menu-content table td{padding: 7px 5px;}

  .button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 24px;
    height: 24px;
    float: right;

    &:hover {
      fill: ${props => props.theme.colors.red};
    }
  }
  .menu-content {
    max-height: 916px;
    overflow: auto;
  }
`;
