import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { Button, SideMenu, SideMenuProps } from 'components';
import BuyProcessFormAddress from './BuyProcessFormAddress';
import BuyProcessFormBuyerInfo from './BuyProcessFormBuyerInfo';
import BuyProcessFormFinancials from './BuyProcessFormFinancials';
import BuyProcessFormDeadline from './BuyProcessFormDeadline';
import {
  BuyProcessBuyerInfoData,
  BuyProcessDeadlineData,
  BuyProcessFinancialsData,
  BuyProcessPriceIncludesData
} from './types';
import { createBuyProcess, getBuyProcess, updateBuyProcess, submitNoOffer } from './api';
import { buyerInfoKeys, financialsKeys, priceIncludesKeys, deadlineKeys } from './formFieds';
import BuyProcessPriceIncludesForm from './BuyProcessFormPriceIncludes';

interface BuyProcessProps extends SideMenuProps {
  listing?: Listing;
  buyProcessId?: string;
  clickedOnNewOffer?: boolean;
  openFromWorkspace?: boolean;
  stepStart?: any;
  onSave?: () => void;
  onSuccess: () => any;
}

const REVIEW_STEP = 6;

const BuyProcess: React.FC<BuyProcessProps> = ({
  listing: listingProp = null,
  buyProcessId,
  openFromWorkspace,
  stepStart,
  onSave,
  onClose,
  onSuccess,
  ...props
}) => {
  const [listing, setListing] = useState<Listing | null>(listingProp);
  // prettier-ignore
  const [stepBuyerInfoData, setStepStepBuyerInfoData] = useState<Partial<BuyProcessBuyerInfoData>>();
  const [stepFinancialsData, setStepFinancialsData] = useState<Partial<BuyProcessFinancialsData>>();
  // prettier-ignore
  const [stepPriceIncludesData, setStepPriceIncludesData] = useState<Partial<BuyProcessPriceIncludesData>>();
  const [stepDeadlineData, setStepDeadlineData] = useState<Partial<BuyProcessDeadlineData>>();
  const [step, setStep] = useState(1);
  const propertyAddress = useRef();
  const container = useRef<HTMLDivElement>(null);
  const [propertyNotListedOnMls, setPropertyNotListedOnMls] = useState(Boolean(false));
  const [notListed, setNotListed] = useState(Boolean(false));

  const saveBuyProcess = useCallback(
    async (options = {}) => {
      const buyProcessData = propertyNotListedOnMls == false ? 
      {
        ...stepBuyerInfoData,
        ...stepFinancialsData,
        ...stepPriceIncludesData,
        ...stepDeadlineData,
        ...options,
        listing_id: listing!.id
      } :
      {
        ...stepBuyerInfoData,
        ...stepFinancialsData,
        ...stepPriceIncludesData,
        ...stepDeadlineData,
        ...options,
        ...listing
      };

      try {
        if (buyProcessId) {
          const { documentId } = await updateBuyProcess(buyProcessId, buyProcessData);
          notify('Offer updated.');
          onSave?.();
          onSuccess?.();
          if (options.save_and_sign && documentId) window.open(`/pdf-editor?id=${documentId}`);
        } else {
          const { documentId } = await createBuyProcess(buyProcessData);
          notify('Offer created.');
          onSave?.();
          onSuccess?.();
          if (options.save_and_sign && documentId) window.open(`/pdf-editor?id=${documentId}`);
        }
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    },
    [stepBuyerInfoData, stepFinancialsData, stepPriceIncludesData, stepDeadlineData, listing, buyProcessId, onSave]
  );

  const saveNoOffer = useCallback(
    async (data = {}, x) => {
      try {
        const { buyProcessId } = await submitNoOffer(data, x);
        notify('Listing created in purchases.');
        onSave?.();
        onSuccess?.();
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    },
    [stepBuyerInfoData, stepFinancialsData, stepPriceIncludesData, stepDeadlineData, listing, buyProcessId, onSave]
  );

  useEffect(() => {
    if(listing && listing.mlsStatus === "Not Listed"){
      setNotListed(true);
    }

    if (!buyProcessId) return;

    (async () => {
      try {
        const data = await getBuyProcess(buyProcessId);
        propertyAddress.current = data.propertyAddress;
        setNotListed(data.notListed);

        const newStepStepBuyerInfoData: Partial<BuyProcessBuyerInfoData> = {};
        const newStepFinancialsData: Partial<BuyProcessFinancialsData> = {};
        const newStepPriceIncludesData: Partial<BuyProcessPriceIncludesData> = {};
        const newStepDeadlineData: Partial<BuyProcessDeadlineData> = {};

        buyerInfoKeys.forEach(key => {
          newStepStepBuyerInfoData[key] = data[key];
        });
        setStepStepBuyerInfoData(newStepStepBuyerInfoData);

        financialsKeys.forEach(key => {
          newStepFinancialsData[key] = data[key];
        });
        setStepFinancialsData(newStepFinancialsData);

        priceIncludesKeys.forEach(key => {
          newStepPriceIncludesData[key] = data[key];
        });
        setStepPriceIncludesData(newStepPriceIncludesData);

        deadlineKeys.forEach(key => {
          newStepDeadlineData[key] = data[key];
        });
        setStepDeadlineData(newStepDeadlineData);

        setStep(6);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [buyProcessId]);

  useEffect(() => {
    if(stepStart) setStep(stepStart);
  }, [stepStart]);

  const handleClose = () => {
    setStepStepBuyerInfoData(undefined);
    setStepFinancialsData(undefined);
    setStepPriceIncludesData(undefined);
    setStepDeadlineData(undefined);
    setPropertyNotListedOnMls(false);
    setStep(1);
    setListing(listingProp);
    onClose?.();
  };

  const goToNextStep = (stepToGo, nextEditingData) => {
    const nextStep = nextEditingData ? stepToGo : stepToGo;
    setStep(nextStep);
  };

  return (
    <StyledBuyProcess onClose={handleClose} {...props}>
      <h1>
        {propertyAddress.current
          ? `Edit the offer for ${propertyAddress.current}`
          : 'New Project'}
      </h1>
      <div className="buy-process-container" ref={container}>
        <p className="step" id="step-1">1. Property Information</p>
        <BuyProcessFormAddress
          clickedOnNewOffer={props.clickedOnNewOffer}
          isEditing={step === 1}
          openFromWorkspace={openFromWorkspace}
          onStartEdit={setStep}
          listing={listing || undefined}
          notListed={notListed}
          onSubmit={(v,w,x) => {
            setListing(v)
            setPropertyNotListedOnMls(x)
            if(w === "offerYes"){
              goToNextStep(2, stepBuyerInfoData);
            }else{
              saveNoOffer(v,x);
              handleClose();
            }
          }}
        />

        {(step >= 2 || stepBuyerInfoData) && (
          <>
            <p className="step" id="step-2">2. Buyer Information</p>
            <BuyProcessFormBuyerInfo
              defaultValues={stepBuyerInfoData}
              isEditing={step === 2}
              onStartEdit={setStep}
              onSubmit={v => {
                setStepStepBuyerInfoData(v);
                goToNextStep(3, stepFinancialsData);
              }}
            />
          </>
        )}

        {(step >= 3 || stepFinancialsData) && (
          <>
            <p className="step" id="step-3">3. Financials</p>
            <BuyProcessFormFinancials
              listing={listing!}
              isEditing={step === 3}
              onStartEdit={setStep}
              defaultValues={stepFinancialsData}
              onSubmit={v => {
                setStepFinancialsData(v);
                goToNextStep(4, stepPriceIncludesData);
              }}
            />
          </>
        )}

        {(step >= 4 || stepPriceIncludesData) && (
          <>
            <p className="step" id="step-4">4. Price Includes</p>
            <BuyProcessPriceIncludesForm
              defaultValues={stepPriceIncludesData}
              isEditing={step === 4}
              onStartEdit={setStep}
              onSubmit={v => {
                setStepPriceIncludesData(v);
                goToNextStep(5, stepDeadlineData);
              }}
            />
          </>
        )}

        {(step >= 5 || stepDeadlineData) && (
          <>
            <p className="step" id="step-5">5. Closing Information</p>
            <BuyProcessFormDeadline
              isEditing={step === 5}
              onStartEdit={setStep}
              defaultValues={stepDeadlineData}
              onSubmit={v => {
                setStepDeadlineData(v);
                setStep(6);
              }}
            />
          </>
        )}

        {step === 6 && (
          <div className="save-buttons">
            <Button
              secondary
              onClick={() => {
                saveBuyProcess();
                handleClose();
              }}>
              Save
            </Button>
            <Button onClick={() => {
              saveBuyProcess({ save_and_sign: true });
              handleClose();
            }}>
              Save and Continue
            </Button>
          </div>
        )}
      </div>
    </StyledBuyProcess>
  );
};

export default BuyProcess;

const StyledBuyProcess = styled(SideMenu)`
  .side-menu {
    display: flex;
    flex-direction: column;
    width: 1024px;
    padding-bottom: 20px;

    & > h1 {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      margin-top: 0;
    }
  }

  .buy-process-container {
    padding-bottom: 40px;
    .step {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      margin: 20px 0px;
      color: ${props => props.theme.colors.red};
    }

    .step-info {
      border: 1px solid ${props => props.theme.colors.borderColor};
      border-radius: 3px;
      min-height: 86px;
      padding: 16px;
      margin: 20px 0px;
      display: flex;

      &__content {
        margin-right: 28px;
        width: 100%;

        & > span {
          display: inline-block;
          margin: 0 16px 6px 0;
          color: #9c9c9e;
        }
      }

      &__edit-btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: ${props => props.theme.colors.red};
        margin-left: auto;
      }

      &__primary {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin: 8px 0 6px 0;
      }

      &__secondary {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #9c9c9e;
        margin: 0;
      }
    }

    .listing-photo {
      width: 54px;
      height: 54px;
      margin-right: 26px;
    }

    form {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;

      .input {
        .input-component {
          height: 40px;
        }

        &.address {
          width: 100%;
          max-width: 480px;
        }
      }

      .submit-button {
        width: 112px;
        height: 36px;
        margin: auto 0 0 auto;
      }
    }

    .save-buttons {
      display: flex;
      justify-content: flex-end;

      .button {
        margin-left: 20px;
        min-width: 112px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .buy-process-container {
      height: auto;
    }
  }
`;
