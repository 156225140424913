import React, { useState } from 'react';

import styled from '@emotion/styled';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { Button, Checkbox } from 'components';
import IdentifySummary from './IdentifySummary';
import PropertyInfoSummary from './PropertyInfoSummary';
import PropertyDetailsSummary from './PropertyDetailsSummary';
import FinancialsSummary from './FinancialsSummary';
import { submitSellProcessForm } from './api';
interface ReviewProps {
  data: { [key: string]: any; };
  listing: Listing;
  onEdit: (value: number) => void;
  editing?: boolean;
  onSuccess?: () => void;
}

const Review: React.FC<ReviewProps> = ({ data, listing, onEdit, editing, onSuccess }) => {
  const [reviewed, setReviewed] = useState<boolean>(false);

  const onSubmit = async (_e: React.MouseEvent<HTMLButtonElement>, navigate?: (value: number) => void) => {
    try {
      const documentId = await submitSellProcessForm(data);
      notify(`Listing agreement ${editing ? 'updated' : 'created'}`);
      onSuccess?.();
      if (documentId && navigate) navigate(documentId);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  return (
    <StyledReview>
      <div className="process-form">
        {/* Identify Summary */}
        <IdentifySummary
          data={{ ...listing, current_price: data.current_price }}
        />

        {/* Property Information */}
        <PropertyInfoSummary data={data} onEdit={() => onEdit(0)} />

        {/* Property Details */}
        {/*<PropertyDetailsSummary data={data} onEdit={() => onEdit(1)} condo={Boolean(data.condoPUD)} />*/}

        {/* Financials */}
        <FinancialsSummary data={data} onEdit={() => onEdit(1)} />

        <div className="submit-block">
          {/*<Checkbox
            checked={reviewed}
            onChange={() => setReviewed(v => !v)}
            label="I have reviewed and agreed to all above"
          />
          <Button
            secondary
            disabled={!reviewed}
            className="process-form__btn"
            onClick={onSubmit}>
            Save
          </Button>*/}
          <Button
            disabled={reviewed}
            onClick={e => onSubmit(e, documentId => window.open(`/pdf-editor?id=${documentId}`))}
            className="process-form__btn"
          >
            Submit
          </Button>
        </div>
      </div>
    </StyledReview>
  );
};

const StyledReview = styled.div`
  .submit-block {
    display: flex;
    align-items: center;
    .process-form__btn {
      margin: 0;
      &:first-of-type {
        margin: 0 16px 0 auto;
      }
    }
  }
  .review-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 24px;
  }
  .review-wrapper {
    border-bottom: 1px solid #c4c4c4;
    margin: 0 0 32px;
  }
  .review-header {
    display: flex;
    justify-content: space-between;
  }
  .review-block {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 32px;
    padding: 0 0 32px;
    flex: 1;
    .review-block:last-of-type {
      padding: 0;
    }
  }
  .review-info-block {
    &__title {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      margin: 0 0 12px;
    }
  }
  .review-text {
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 8px;
    font-weight: normal;
    strong {
      font-weight: 500;
    }
  }
  .property-price {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }
  .property-block {
    flex: 1;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    display: flex;
    img {
      width: 24px;
      height: 24px;
      border-radius: 3px;
      margin-right: 12px;
      object-fit: cover;
    }
    span {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .review-block {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    .property-block {
      &:first-of-type {
        margin: 0 0 32px;
      }
    }
    .header-property-info {
      .review-title {
        margin: 0 0 16px;
      }
    }
    .submit-block {
      flex-direction: column;
      .checkbox {
        margin: 0 0 24px;
      }
      .process-form__btn:first-of-type {
        margin: 0 0 16px;
      }
    }
  }
`;

export default Review;
