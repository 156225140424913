import { useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import notify from 'notify';

import WorkspaceContext from 'workspace/WorkspaceContext';
import { tryGetFirstError } from 'utils/requests';
import { getActivityLog } from '../api';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export default () => {
  const { listingId } = useContext(WorkspaceContext);
  const [activityItems, setActivityItems] = useState<WorkspaceActivityLogItem[]>();

  const groupItems = useMemo(() => {
    return (
      activityItems
        ?.sort((a, b) => b.createdOn - a.createdOn)
        .reduce((accum, current) => {
          const date = dayjs(current.createdOn);
          let key;
          if (date.isYesterday()) key = 'Yesterday';
          else if (date.isToday()) key = 'Today';
          else key = date.format('MM/DD/YYYY');
          // eslint-disable-next-line no-param-reassign
          accum[key] = accum[key] ? [...accum[key], current] : [current];
          return accum;
        }, {}) || {}
    );
  }, [activityItems]);

  useEffect(() => {
    if (activityItems) return;
    (async () => {
      try {
        const activityItems = await getActivityLog(listingId);
        setActivityItems(activityItems);
      } catch (err) {
        notify(tryGetFirstError(err));
      }
    })();
  }, [activityItems, listingId]);

  return {
    activities: Object.entries(groupItems)
  };
};
