import { fetchWithToken } from 'utils/fetch';
import { mapListings } from 'utils/mapping';
import { urlEncodeBody, checkSuccess } from 'utils/requests';
import { info } from 'sitedata';

export async function getBuyProcess(id: string): Promise<any> {
  const response = await fetchWithToken(`/offers/${id}`);
  const data = await response.json();

  return JSON.parse(data.data);
}

export async function getListingByAddress(address: string): Promise<Listing> {
  const query = urlEncodeBody({ address }).toString();
  const response = await fetchWithToken(`/listings/obtain_mls_id`, { query });
  if (response.status === 204) throw Error('Listing not found');
  const data = await response.json();

  return mapListings([data.listing])[0];
}

export async function searchListingsByAddressOrMlsId(
  searchString: string,
  signal?: AbortController['signal']
): Promise<Listing[]> {
  const query = urlEncodeBody({ search_string: searchString, without_closed: 1 }).toString();
  const response = await fetchWithToken(`${info.commonApiUrl}/listings/search/list`, {
    query,
    signal
  });

  if (response.status === 204) throw Error('Listing not found');
  const data = await response.json();

  checkSuccess(data);

  return mapListings(data);
}

export async function createBuyProcess(params: {
  [key: string]: any;
}): Promise<{
  buyProcessId: string;
  documentId: number;
}> {
  const body = urlEncodeBody({ data: JSON.stringify(params) });
  const response = await fetchWithToken(`/offers/full`, { method: 'POST', body });

  const data = await response.json();

  return {
    buyProcessId: data.buy_process_id,
    documentId: data.document_id
  };
}

export async function updateBuyProcess(
  id: string,
  params: { [key: string]: any }
): Promise<{
  buyProcessId: string;
  documentId: number;
}> {
  const body = urlEncodeBody({ data: JSON.stringify(params) });
  const response = await fetchWithToken(`/offers/${id}`, { method: 'PUT', body });

  const data = await response.json();

  return {
    buyProcessId: data.buy_process.buy_process_id,
    documentId: data.buy_process.document_id
  };
}

export async function submitNoOffer(
  params: { [key: string]: any },
  propertyNotListedOnMls: boolean
): Promise<{
  buyProcessId: string;
}> {
  const postData = propertyNotListedOnMls == false ? 
  {
    propertyAddress : params.googleAddress,
    listing_id : params.listingId
  } :
  {
    ...params
  }
  
  const body = urlEncodeBody({ data: 
    JSON.stringify(postData)
  });

  const response = await fetchWithToken(`/offers/no_offer`, { method: 'POST', body });
  const data = await response.json();

  return {
    buyProcessId: data.buy_process_id,
  };
}
