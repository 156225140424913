import { fetchWithToken } from 'utils/fetch';

export async function getUsers(): Promise<any> {
  const response = await fetchWithToken('/users');
  const data = await response.json();

  return data;
}

export async function getUsersDetailed(): Promise<any> {
  const response = await fetchWithToken('/users/detailed');
  const data = await response.json();

  return data;
}
