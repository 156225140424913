import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { Button, Input } from '@xchange/uikit';
import { InputCurrency } from 'components';
interface ExpenseFormProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  onCancel: () => void;
  onSubmit: (values: {
    expense: number;
    expense_description: string;
  }) => void;
}

const ExpenseForm: React.FC<ExpenseFormProps> = ({
  onCancel,
  onSubmit
}) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      expense: 0,
      expense_description: ''
    }
  });

  return (
    <StyledExpenseForm onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        rules={{
          required: 'Required'
        }}
        name="expense"
        defaultValue={0}
        render={({ field, formState: { errors } }) => (
          <InputCurrency
            label="Amount*"
            placeholder="Enter Amount"
            error={errors.expense?.message}
            {...field}
          />
        )}
      />
      <Input
        {...register('expense_description', {
          required: 'Required'
        })}
        label="Description"
        placeholder="Enter Description*"
        error={errors.expense_description?.message}
      />
      <div className="button-group">
        <Button secondary onClick={onCancel}>Cancel</Button>
        <Button>Add</Button>
      </div>
    </StyledExpenseForm>
  );
};
export default ExpenseForm;

const StyledExpenseForm = styled.form`
  margin-bottom: auto;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 16px;
  row-gap: 16px;
  .input {
    display: block;
  }
  .button-group {
    margin-top: 24px;
    grid-column: span 2;
    .button + .button {
      margin-left: 12px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    .input {
      margin-bottom: 16px;
    }
  }
`;
